import { useGetAuth } from "contexts/AuthContext";
import { cloneDeep, isEmpty, pickBy } from "lodash";
import useCastleFP from "./castle";
import useFingerprint from "./fingerprint";
import { encryptedKeysFP, payloadFormatterFP } from "./tools";
import { apiBusiness } from "tools/api";
import { mainURL, urlDeciderChild } from "../interceptors/url";

interface Payload {
  [key: string]: any;
}

interface ConfigAPI {
  data: {
    data: {
      fingerprint_status?: "false" | "true";
      fingerprint_status_inprod?: "false" | "true";
    };
  };
}

// isSecretKey => only applicable when user logs in
export const useFingerprinting = () => {
  const { user } = useGetAuth();

  const getCastleData = useCastleFP();
  const getSalusData = useFingerprint();

  const getSalusStatus = async () => {
    const { data }: ConfigAPI = await apiBusiness.get("/config");
    const { fingerprint_status, fingerprint_status_inprod } = data?.data || {};

    const url = urlDeciderChild(mainURL);

    const isInprod =
      url.includes("api.enterprise.transfez.app") || url.includes("inprod");

    if (isInprod) return fingerprint_status_inprod === "true";
    return fingerprint_status === "true";
  };

  const getData = async (email?: string) => {
    const isSalus = await getSalusStatus();

    if (isSalus) {
      return getSalusData();
    }
    return getCastleData(email);
  };

  const format = async (payloadRaw?: Payload) => {
    const isFormData = payloadRaw instanceof FormData;

    if (isFormData) return payloadRaw;

    const payload = cloneDeep(payloadRaw);
    const isEncrypted =
      encryptedKeysFP(payload).isEncrypted ||
      encryptedKeysFP(payload?.data).isEncrypted;

    const email = payload?.email || user?.email;

    const runFp = !isEmpty(
      pickBy(
        payload || {},
        (_, key) => key.includes("otp_code") || key.includes("email")
      )
    );

    const isWithoutFingerprint = !isEncrypted && !runFp;

    if (isWithoutFingerprint) return payload;

    if (!payload) return payload;

    const { secretKey, ...res } = await getData(email);

    if (isEncrypted) {
      const result = payloadFormatterFP(payload, secretKey);

      if (result?.data?.pin) {
        result.data = { ...result.data, ...res };
        return result;
      }

      return { ...result, ...res };
    }

    return { ...payload, ...res };
  };

  return { getData, format };
};
