import { getCookie, setCookie } from "@tools";
import Castle, { Thenable } from "@castleio/castle-js";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

const useCastle = () => {
  const [castle, setCastle] = useState<{
    createRequestToken?: () => Thenable<string>;
  }>({});

  useEffect(() => {
    if (!isEmpty(castle)) return;

    setCastle(
      Castle.configure({
        pk: process.env.NEXT_PUBLIC_CASTLE_API,
      })
    );
  }, []);

  const { createRequestToken } = castle || {};

  const getCastleToken = async () => {
    if (!createRequestToken) return "";
    return await createRequestToken();
  };

  return { castle, getCastleToken };
};

const useCastleFP = () => {
  const { getCastleToken } = useCastle();

  const getCastleData = async (email?: string) => {
    const cookie = getCookie("castle");

    let castle = cookie;

    if (cookie) {
      castle = cookie;
    } else {
      castle = await getCastleToken();
      setCookie("castle", castle);
    }

    const userAgent = window.navigator.userAgent;

    const obj = { castle, userAgent };

    const secretKey = email ? castle.slice(0, 20) + email : "";

    return { ...obj, secretKey };
  };

  return getCastleData;
};

export default useCastleFP;
