import axios, { AxiosError, AxiosInstance, CancelTokenSource } from "axios";
import { useConstants, UserJack } from "contexts/ConstantsContext/parent";
import { isEmpty } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { ToasterContext } from "contexts/ToasterContext";

interface APIs {
  strapi: AxiosInstance;
  apiBusinessLong: AxiosInstance;
  apiBusinessV2: AxiosInstance;
  apiBusiness: AxiosInstance;
}

export interface FetchParams<T> {
  url: string;
  formatter?: (val: any, prev?: T, secondData?: any) => T;
  type?: string;
  noToaster?: boolean;
  defaultValue?: T;
  woInit?: boolean;
  defaultLoading?: boolean;
  afterSuccess?: (data: any, secondData?: any) => Promise<void> | void;
  params?: Record<string, any>;
  errorHandler?: () => void;
  isLocalApi?: boolean;
  additionalFetch?: () => any;
}

interface FetchParamLocal<T> extends FetchParams<T>, APIs {}

const isUserDetailsChecker = (path: string) => {
  // Define the regular expression pattern
  const pattern = /^\/business_users\/\d+$/;

  // Test the path against the pattern and return the result
  return pattern.test(path);
};

export const fetchRaw = <T>({
  url,
  formatter = (val) => val,
  type,
  noToaster = true,
  defaultValue = [] as T,
  woInit,
  defaultLoading,
  afterSuccess,
  params = {},
  errorHandler = () => {},
  isLocalApi,
  additionalFetch = () => {},
  strapi,
  apiBusinessLong,
  apiBusinessV2,
  apiBusiness,
}: FetchParamLocal<T>) => {
  const apiDecider = (type?: string) => {
    if (type == "axios") return axios;
    if (type == "strapi") return strapi;
    if (type == "long") return apiBusinessLong;
    if (type == "v2") return apiBusinessV2;
    return apiBusiness;
  };
  const getUser = async (users: UserJack[], id: number | string) => {
    const user = users.find((item) => item.id == id);

    if (!isEmpty(user)) return user;

    const { data } = await apiBusiness.get(
      `/business_users/all_users?q[id_eq]=${id}`
    );

    const deletedUser = data?.data[0];

    return deletedUser;
  };

  const { errorToasterApi } = useContext(ToasterContext);
  const [loading, setLoading] = useState(
    defaultLoading ? defaultLoading : !woInit
  );

  const { users: usersJack } = useConstants();
  const users = usersJack || [];

  const [data, setData] = useState<T>(defaultValue);

  const isUserDetails = isUserDetailsChecker(url);

  const ref = useRef<null | CancelTokenSource>(null);

  const getData = async () => {
    const cancelTokenSource = axios.CancelToken.source();

    if (ref.current) {
      ref.current.cancel();
    }

    ref.current = cancelTokenSource;

    try {
      setLoading(true);
      const api = isLocalApi ? axios : apiDecider(type);

      const afterFetch = async (data: any, secondData: any) => {
        setData((prev) => formatter(data, prev, secondData));
        afterSuccess && (await afterSuccess(data, secondData));
      };

      const secondData = await additionalFetch();

      if (isUserDetails) {
        // needs to put this coz in `business_users/:id` if the user is deleted it cant access the deleted users
        if (isEmpty(users)) return setLoading(false);
        const id = url.split("/business_users/").pop();

        id &&
          (await afterFetch({ data: await getUser(users, id) }, secondData));
        return;
      }

      const { data } = await api.get(url, {
        params,
        cancelToken: cancelTokenSource.token,
      });

      // if there is user_id it automatically adds user obj here
      const user_id = data?.data?.user_id;

      if (user_id) {
        data.data = {
          ...(data?.data || {}),
          user: await getUser(users, user_id),
        };
      }

      await afterFetch(data, secondData);
    } catch (error) {
      if (axios.isCancel(error)) return;

      const err = error as AxiosError;

      errorHandler();
      const is401 = err?.response?.data?.status === 401;
      if (is401) {
        errorToasterApi(err);
        setLoading(false);
        return;
      }
      if (noToaster) return;
      errorToasterApi(err);
    } finally {
      setLoading(false);
      ref.current = null;
    }
  };

  useEffect(() => {
    if (woInit) return;
    if (isUserDetails) return;
    getData();
  }, []);

  useEffect(() => {
    if (!isUserDetails) return;
    if (isEmpty(users)) return;
    getData();
  }, [isEmpty(users)]);

  return { data, loading, refetch: getData, setData };
};
