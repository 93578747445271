import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { getCookie, setCookie } from "@tools";
import { SecretKeyPayload } from "tools/api/functions/hooks/mutation/payload/hash";

const useFingerprint = () => {
  const { getData } = useVisitorData(
    { ignoreCache: true },
    { immediate: false }
  );

  const getSalusData = async () => {
    let salus = "";
    let visitorId = "";

    const cookie = getCookie("salus");

    if (cookie) {
      const { salus: salusCookie, visitorId: visitorIdCookie } =
        JSON.parse(cookie);
      salus = salusCookie;
      visitorId = visitorIdCookie;
    } else {
      const { requestId, visitorId: visitorIdGenerated } = await getData();

      salus = requestId;
      visitorId = visitorIdGenerated;

      setCookie("salus", JSON.stringify({ salus, visitorId }));
    }

    const secretKey = `${SecretKeyPayload}${visitorId}`;

    return { salus, secretKey };
  };

  return getSalusData;
};

export default useFingerprint;
