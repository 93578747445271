import { useForm } from "@tools";
import { useActiveModules } from "contexts/AuthContext";
import { get, isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Row, Tooltip } from "reactstrap";
import { colors, jackColors } from "../assets/colors";
import beingVerified from "../assets/images/being-verified.svg";
import expiredImg from "../assets/images/expired-transaction.svg";
import hugeCheck from "../assets/images/huge-check.svg";
import topupIcon from "../assets/images/topup-icon.svg";
import xIcon from "../assets/images/x_calculator.svg";
import { JackIcons } from "../assets/jackIcons/parent";
import { CustomTextField } from "../components/inputs";
import {
  applyRegexLetters,
  applyRegexNumbers,
  dataSorter,
} from "../components/tools";
import bankIconDecider from "../constants/bankIconDecider";
import useBankProcedureDecider from "../constants/bankProcedureDecider";
import { ToasterContext } from "../contexts/ToasterContext";
import { WrapperModalButtons } from "../pageComponents/homeComponents/topup/components";
import {
  InputAmountModal,
  TopUpManualModal,
  TopUpPaymentInstructionModal,
} from "../pageComponents/homeComponents/topup/modals";
import { apiBusiness, fetch } from "../tools/api";
import { eventsTracker } from "../universalFunctions/events";
import BankCard from "./BankCard";
import { Banner } from "./Banner";
import { CustomButton } from "./Buttons";
import { ButtonJack } from "./ButtonsJack/parent";
import { RightModal } from "./Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "./Text";
import { windowDimension } from "./tools";

export const useModalHook = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState(defaultValue);
  const toggle = () => {
    setIsOpen((p) => !p);
  };
  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };
  return { toggle, isOpen, close, open };
};

export const AtomicNotifyModal = ({
  toggle,
  isOpen,
  icon = null,
  title,
  msg,
}) => {
  const modalWidth = useModalWidth();
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          backgroundColor: "white",
          width: modalWidth,
          flexDirection: "column",
          borderRadius: 12,
          padding: 24,
          paddingLeft: 32,
          paddingRight: 32,
        }}
        className="d-flex align-items-center justify-content-between"
      >
        <div
          className="align-items-center d-flex"
          style={{ flexDirection: "column" }}
        >
          <GothamMedium
            style={{
              fontSize: 24,
              textAlign: "center",
              marginBottom: 24,
              color: colors.grey72,
            }}
          >
            {title}
          </GothamMedium>
          {icon}
          <GothamRegular
            style={{
              fontSize: 16,
              lineHeight: "24px",
              textAlign: "center",
              marginTop: 24,
            }}
          >
            {msg}
          </GothamRegular>
        </div>
        <CustomButton
          style={{ width: "100%", marginTop: 24, fontSize: 16 }}
          onClick={toggle}
        >
          OK
        </CustomButton>
      </div>
    </Modal>
  );
};

export const NotifyModal = ({
  toggle,
  isOpen,
  title = "Success!",
  msg = "",
  icon = <img src={hugeCheck} />,
}) => (
  <AtomicNotifyModal
    icon={icon}
    toggle={toggle}
    isOpen={isOpen}
    title={title}
    msg={msg}
  />
);

export const BeingVerifiedModal = ({ isOpen, toggle }) => (
  <NotifyModal
    isOpen={isOpen}
    toggle={toggle}
    icon={<img src={beingVerified} />}
    msg="Your account is being verified. Please wait until your verfication process is complete to use this feature "
    title="Your account is being verified"
  />
);

export const HeaderModal = ({ toggle, title }) => {
  const { figmaHeight } = windowDimension();
  return (
    <Row
      className="mx-0 justify-content-between"
      style={{ marginBottom: figmaHeight(24) }}
    >
      <GothamMedium
        className="mt-2"
        style={{
          fontSize: 24,
          lineHeight: "32px",
          color: colors.teal,
          lineHeight: "24px",
          maxWidth: "90%",
        }}
      >
        {title}
      </GothamMedium>
      <img src={xIcon} className="hover" onClick={toggle} />
    </Row>
  );
};

export const RefundModal = ({ isOpen, toggle, item, isSingle }) => {
  const getTrxIds = get(item, "trxIds", []);
  const hasTrxIds = Boolean(getTrxIds.length);
  const [loading, setLoading] = useState(false);
  const { figmaHeight } = windowDimension();
  const useFormObj = useForm();
  const { bank_name, account_name, account_number } = item;
  const { query, push } = useRouter();
  const { id, transactionId } = query;
  const { errorToasterApi } = useContext(ToasterContext);

  const submit = async (val) => {
    try {
      setLoading(true);
      if (hasTrxIds) {
        const url = `/refund_transactions`;
        const sendData = { ...val, approve: getTrxIds };
        await apiBusiness.post(url, sendData);
        eventsTracker("refund_transactions", { ids: getTrxIds });
      } else if (isSingle) {
        const url = `/refund_transactions`;
        const sendData = { ...val, approve: [transactionId || id] };
        await apiBusiness.post(url, sendData);
        eventsTracker("refund_transactions", { ids: getTrxIds });
      } else {
        const url = `/jobs/${id}/refunds/create_refund_batch`;
        await apiBusiness.post(url, val);
        eventsTracker("refund_batch", { batch_id: id });
      }
      push({ query: { ...query, refresh: true } });
      toggle({});
    } catch (error) {
      console.log(error);
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, watch, setValue } = useFormObj;

  const watchBank = watch("bank_name");
  const watchAccName = watch("account_name");
  const watchAccNum = watch("account_number");
  const checkBank = applyRegexLetters(watchBank || "");
  const checkAccName = applyRegexLetters(watchAccName || "");
  const checkAccNum = applyRegexNumbers(watchAccNum || "");

  useEffect(() => {
    if (checkBank) {
      setValue("bank_name", watchBank.replace(checkBank, ""));
    }
    if (checkAccName) {
      setValue("account_name", watchAccName.replace(checkAccName, ""));
    }
    if (checkAccNum) {
      setValue("account_number", watchAccNum.replace(checkAccNum, ""));
    }
  }, [watchBank, watchAccName, watchAccNum]);

  const emptyString = (string) => string == "";
  const FormRefundData = [
    {
      name: "bank_name",
      label: "Your bank name",
      defaultValue: bank_name,
      length: 51,
      error: emptyString(watchBank),
    },
    {
      name: "account_name",
      label: "Your account name",
      defaultValue: account_name,
      length: 51,
      error: emptyString(watchAccName),
    },
    {
      name: "account_number",
      label: "Your account number",
      defaultValue: account_number,
      length: 17,
      error: emptyString(watchAccNum),
    },
  ];

  return (
    <Modal
      toggle={() => toggle({})}
      isOpen={isOpen}
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          backgroundColor: "white",
          width: 712,
          borderRadius: 12,
        }}
        className="p-4"
      >
        <Row
          className="mx-0 justify-content-between"
          style={{ marginBottom: figmaHeight(24) }}
        >
          <GothamMedium
            className="mt-2"
            style={{
              fontSize: 24,
              color: colors.teal,
              lineHeight: "24px",
            }}
          >
            Refund
          </GothamMedium>
          <img src={xIcon} className="hover" onClick={() => toggle({})} />
        </Row>
        <GothamRegular className="mb-4" style={{ fontSize: 16 }}>
          Refund to bank account
        </GothamRegular>
        <form onSubmit={handleSubmit(submit)}>
          {FormRefundData.map(({ length, ...props }, index) => {
            return (
              <CustomTextField
                key={index}
                {...props}
                useFormObj={useFormObj}
                inputProps={{ maxLength: length }}
              />
            );
          })}
          <div className="d-flex justify-content-end">
            <CustomButton
              isLoading={loading}
              type="submit"
              className="px-4"
              style={{
                fontSize: 20,
                height: 48,
              }}
            >
              Submit
            </CustomButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export const useModalWidth = (defaultWidth = 516) => {
  const { isTabOrPhone, width } = windowDimension();
  const modalWidth = isTabOrPhone ? width - 16 : defaultWidth;
  return modalWidth;
};

export const TimerModal = ({ isOpen, onCloseModal }) => {
  const { t } = useTranslation("international/create");
  const { isTabOrPhone, width } = windowDimension();
  return (
    <>
      <Modal
        isOpen={isOpen}
        backdrop={"static"}
        keyboard={false}
        centered
        contentClassName="modal-transfez"
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 12,
            width: isTabOrPhone ? width : 438,
            textAlign: "center",
            padding: "24px 32px",
          }}
        >
          <img src={expiredImg} style={{ marginBottom: 32 }} />
          <GothamMedium className="font20" style={{ marginBottom: 16 }}>
            {t("Your transaction has expired")}
          </GothamMedium>
          <GothamRegular
            style={{
              lineHeight: "20px",
              marginBottom: 32,
              color: jackColors.neutral800,
            }}
          >
            {t(
              "It happened due to a currency rate change. Please initiate a new transaction and complete it within 10 minutes to prevent any error."
            )}
          </GothamRegular>
          <ButtonJack style={{ width: "100%" }} onClick={onCloseModal}>
            {t("Got it")}
          </ButtonJack>
        </div>
      </Modal>
    </>
  );
};

const dataBCA = {
  account_name: "INDO KOALA REMITTANCE PT",
  bank: "Bank BCA",
  code: "008",
  is_active: true,
  number: "5230636399",
};

const dataMandiri = {
  account_name: "INDO KOALA REMITTANCE PT",
  bank: "Bank Mandiri",
  code: "008",
  is_active: true,
  number: "1290007394089",
};

const BankSelection = ({
  selectedBank,
  banks,
  banksRobot,
  handleClick,
  isPrepaid,
  isPaymentInstruction,
}) => {
  const [isExpandedIndex, setIsExpandedIndex] = useState(0);
  const banksIsFilled = Boolean(banks.length);

  const stateObj = {
    isExpandedIndex: isExpandedIndex,
    setIsExpandedIndex: setIsExpandedIndex,
  };

  const { t } = useTranslation("topup");
  const { t: tLedgers } = useTranslation("ledgers/ledgers");
  const procedure = useBankProcedureDecider(selectedBank?.bank);

  if (isPaymentInstruction) {
    const procedureKeys = Object.keys(procedure).map((procedure) => procedure);
    const procedureValues = Object.values(procedure).map(
      (procedure) => procedure
    );
    return (
      <div style={{ marginTop: 32 }}>
        <GothamMedium className="font12" style={{ marginBottom: 16 }}>
          {t("How to make the payment")}
        </GothamMedium>
        <div className="borderE6" style={{ padding: 12, borderRadius: 8 }}>
          {procedureKeys.map((procedure, index) => (
            <ProcedureComponent
              key={procedure}
              procedure={procedure}
              procedures={procedureValues[index]}
              procedureKeys={procedureKeys}
              index={index}
              stateObj={stateObj}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ padding: "32px 0px" }}>
        <GothamMedium>{tLedgers("Virtual Account")}</GothamMedium>
        {banksIsFilled &&
          banks.map((bank) => {
            const { bank: bankName, code } = bank;
            return (
              <BankCard
                bank={bank}
                bankName={bankName}
                key={code}
                onClick={handleClick}
              />
            );
          })}
      </div>
      {/* {!isPrepaid && (
        <div>
          <GothamMedium>{tLedgers("Bank Transfer")}</GothamMedium>
          {banksIsFilled &&
            banksRobot.map((bank) => {
              const { bank: bankName, code } = bank;
              const isBCA = bankName === "Bank BCA";

              return (
                <BankCard
                  bank={bank}
                  bankName={bankName}
                  key={code}
                  onClick={() =>
                    handleClick({
                      type: isBCA ? "bank_transfer" : "select_bank",
                      value: !isBCA && bank,
                    })
                  }
                />
              );
            })}
        </div>
      )} */}
    </>
  );
};

const CopyArrays = ({ item, style, index }) => {
  const isFirst = index === 0;
  const { title, value } = item || {};
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const { t } = useTranslation("ledgers/ledgers");

  return (
    <div style={{ marginTop: !isFirst && 16, ...style }}>
      <div>
        <GothamRegular
          className="mb-1 font12"
          style={{ color: jackColors.neutral700 }}
        >
          {title}
        </GothamRegular>
        <GothamMedium
          style={{
            marginRight: 8,
            cursor: "pointer",
            color: jackColors.neutral900,
          }}
        >
          {value}
        </GothamMedium>
      </div>
      <div
        id={"Tooltip-" + (index + 1)}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          // successToaster({ msg: "Item is Copied" });
          navigator.clipboard.writeText(value);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <JackIcons
          name="file_copy"
          fill="black"
          style={{ width: 20, height: 20 }}
        />
      </div>
      <Tooltip
        target={"Tooltip-" + (index + 1)}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        style={{ padding: "0px 12px 2px 12px" }}
      >
        <span
          style={{
            fontSize: 10,
            lineHeight: "12px",
            fontFamily: "TypefezNeueRegular",
          }}
        >
          {t("Copy")}
        </span>
      </Tooltip>
    </div>
  );
};

export const TopUpModal = ({
  toggle,
  modal,
  paid,
  onClickPaid = () => {},
  onSelectBank = () => {},
  dummyPaidButton = false,
  url = "/my_virtual_accounts",
  customCloseText,
  title,
  customHeader,
  isPrepaid,
  woTransition,
  woSubtitle,
  isShowBack,
  customToggle,
  woClose,
  noModal,
  noIcon = false,
  isUltimate,
  isLocalTransfer = false,
}) => {
  const { t } = useTranslation("topup");
  // if (!modal) return null;
  // const { t } = useTranslation("dashboard/dashboard");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  // STATES
  const { successToaster } = useContext(ToasterContext);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);

  const { isActiveTopup } = useActiveModules();

  const isMandiri = selectedBank?.bank === "Bank Mandiri";

  const isPaymentInstruction = !!selectedBank && !isMandiri;

  // STATE CHECKERS
  const banksIsFilled = Boolean(banks.length);

  // FETCHES
  const { data: VABanks, refetch } = fetch({
    url,
    woInit: true,
    formatter: (res) => res?.data?.filter(({ is_active }) => is_active),
  });

  // filter out mandiri from VABanks to prevent dupes
  const VAWithoutMandiri = VABanks?.filter(
    ({ bank }) => bank !== "Bank Mandiri"
  );

  // hardcode insert mandiri
  const VAHardCodeMandiri = isPrepaid
    ? VAWithoutMandiri || []
    : [
        ...(VAWithoutMandiri || []),
        {
          bank: "Bank Mandiri",
          code: "008",
          number: "-",
          is_active: true,
          account_name: "-",
        },
      ];

  const VaBanksSorted = dataSorter(VAHardCodeMandiri || [], "bank");

  const banksRobot = [dataBCA];

  // SIDE EFFECTS

  useEffect(() => {
    if (!VABanks) return;
    if (isEmpty(VABanks)) return;
    setBanks([...VaBanksSorted]);
  }, [VABanks]);

  useEffect(() => {
    onSelectBank(isPaymentInstruction);
  }, [isPaymentInstruction]);

  useEffect(() => {
    const isUrlContainUndefined = url.includes("undefined");
    if (isUrlContainUndefined) return;
    refetch();
  }, [url]);

  const { isOpen: isOpenProofPayment, toggle: toggleProofPayment } =
    useModalHook();
  const { isOpen: isOpenInputAmountModal, toggle: toggleInputAmountModal } =
    useModalHook();
  const { isOpen: isOpenPaymentInstruction, toggle: togglePaymentInstruction } =
    useModalHook();

  // HANDLER FUNCTIONS

  const handleBack = ({ type }) => {
    setSelectedBank(null);
    if (type === "input") {
      toggleInputAmountModal();
    } else {
      togglePaymentInstruction();
    }
  };

  const handleClick = ({ type, value, isBCA }) => {
    const isHandleSelectBank = type === "select_bank";
    const isHandleBack = type === "back";
    const isHandleClose = type === "close";
    const isHandleToggle = type === "toggle";
    const isHandleHavePaid = type === "paid";
    const isBankTransfer = type === "bank_transfer";

    if (isHandleSelectBank) {
      const bank = value.bank;
      const isMandiriVa = bank === "Bank Mandiri";

      eventsTracker("select_topup_bank", { bank });

      if (isMandiriVa) {
        toggleInputAmountModal();
        return setTimeout(() => setSelectedBank(value), 1000);
      }

      setSelectedBank(value);

      return;
    }
    // if (onBack) return onBack();
    if (isHandleBack) return setSelectedBank(null);
    if (isHandleClose) {
      setSelectedBank(null);
      toggle();
    }
    if (isHandleToggle) {
      toggle();
    }
    if (isBankTransfer) return toggleInputAmountModal();
    if (isHandleHavePaid) {
      if (isBCA) return toggleProofPayment();
      onClickPaid();
      dummyPaidButton && setSelectedBank(null);
      // toggle();
    }
  };

  useEffect(() => {
    if (modal && !isActiveTopup) {
      const timeout = setTimeout(() => {
        handleClick({ type: "close" });
      }, 400);

      return () => clearTimeout(timeout);
    }
  }, [modal, isActiveTopup]);

  // INTERNAL COMPONENT SLICING
  const styleFlexBetween = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const isBCA = selectedBank?.bank === "Bank BCA";
  // const isShowAlert = isMandiri;
  const isShowAlert = false;
  const showPaidButton = paid || isBCA;

  // const inputAmountListener = () => {
  //   if (vaNeedsInputAmount) return setVaNeedsInputAmount(null);
  // };

  const desc = () => {
    const titleBank = isBCA || isMandiri ? "" : "Virtual Account";

    const dataRender = [
      {
        title: t("Account Name"),
        value: selectedBank?.account_name,
      },
      {
        title: t("Virtual Account Number"),
        value: selectedBank?.number,
      },
      ...(isUltimate
        ? [
            {
              title: t("Admin fee"),
              value: `IDR 0`,
            },
          ]
        : []),
    ];

    const titleDecider = () => {
      if (isPaymentInstruction) return t("Payment Instruction");

      if (typeof title == "string") return title;

      return t("Select payment method");
    };
    return (
      <div>
        {!noIcon && (
          <>
            {customCloseText && (
              <ButtonJack
                leftIcon={
                  <JackIcons
                    name="cornerUpLeftOutline"
                    fill={colors.neutral900}
                  />
                }
                type="outline"
                onClick={() => {
                  setSelectedBank(null);
                  toggle();
                }}
              >
                {customCloseText}
              </ButtonJack>
            )}
            {!isPaymentInstruction && !customCloseText && (
              <img src={topupIcon} />
            )}
          </>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            marginTop: (!isPaymentInstruction || customCloseText) && 32,
          }}
        >
          {!isPaymentInstruction && isPrepaid && (
            <div
              style={{
                backgroundColor: colors.neutral900,
                width: "32px",
                height: "32px",
                borderRadius: "8px",
                display: "grid",
                placeItems: "center",
              }}
            >
              <JackIcons name="bank" fill={colors.neutral100} />
            </div>
          )}
          <GothamMedium
            style={{
              fontSize: 24,
              lineHeight: "32px",
            }}
          >
            {titleDecider()}
          </GothamMedium>
        </div>
        {isPaymentInstruction && (
          <div>
            <div
              className="borderE6"
              style={{
                backgroundColor: "#FFF",
                marginTop: 20,
                padding: 16,
                borderRadius: 8,
              }}
            >
              <div
                className="d-flex align-items-center"
                style={{
                  marginBottom: 16,
                  paddingBottom: 8,
                  gap: 8,
                  borderBottom: `1px solid ${colors.greye6}`,
                }}
              >
                <img
                  src={bankIconDecider(selectedBank.bank)}
                  style={{ width: 32, height: 32 }}
                />
                <GothamMedium>
                  {selectedBank.bank} {titleBank}
                </GothamMedium>
              </div>

              {dataRender?.map((item, index) => (
                <CopyArrays
                  index={index}
                  item={item}
                  style={styleFlexBetween}
                  key={index}
                />
              ))}
              {isShowAlert && (
                <div style={{ marginTop: 16 }}>
                  <Banner
                    msg={`Make sure to select "OY Indonesia" as service provider with code 89325 `}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {!isPaymentInstruction && !woSubtitle && (
          <GothamRegular
            style={{
              lineHeight: "20px",
              marginTop: 8,
              color: jackColors.grey6C,
            }}
          >
            {t("Choose how you would like to top up your balance.")}
          </GothamRegular>
        )}
        {!!(customHeader && !isPaymentInstruction) && customHeader}
      </div>
    );
  };

  const inputAmountSelectedBankDecider = () => {
    if (isBCA) return "bca";
    if (isMandiri) return "mandiri";
  };

  const [dataAfterTopup, setDataAfterTopup] = useState({});

  const hideButtonsDecider = () => {
    if (isShowBack) return false;
    if (isPaymentInstruction) return false;
    return true;
  };

  const isHideButtons = hideButtonsDecider();

  const backButton = () => (
    <ButtonJack
      type="outline"
      children={t("Back")}
      onClick={() =>
        handleClick({ type: isPaymentInstruction ? "back" : "toggle" })
      }
      style={{ width: "100%" }}
      styleText={{ fontFamily: "GothamBook" }}
      // leftIcon={
      //   isShowBack && (
      //     <JackIcons name="arrow-back-outline" fill={colors.neutral900} />
      //   )
      // }
    />
  );

  const banksComponent = () => (
    <BankSelection
      isPaymentInstruction={isPaymentInstruction}
      banks={banks}
      banksRobot={banksRobot}
      handleClick={handleClick}
      isPrepaid={isPrepaid}
      selectedBank={selectedBank}
      t={tLocalTransfer}
    />
  );

  const array = [
    <WrapperModalButtons
      hideButtons={isHideButtons}
      onClick={() => setSelectedBank(null)}
      childrenButton={
        <div style={{ width: "100%" }}>
          {showPaidButton && (
            <ButtonJack
              children="I have paid"
              onClick={() => handleClick({ type: "paid", isBCA })}
              style={{ width: "100%", marginBottom: 16 }}
            />
          )}
          {isPaymentInstruction && backButton()}
        </div>
      }
    >
      {desc()}
      {banksComponent()}
    </WrapperModalButtons>,
  ];

  const OtherModal = () => (
    <>
      <InputAmountModal
        isOpen={isOpenInputAmountModal}
        toggle={() => handleBack({ type: "input" })}
        selectedBank={inputAmountSelectedBankDecider()}
        setAfterSuccess={(val) => {
          setDataAfterTopup(val);
          togglePaymentInstruction();
        }}
        t={tLocalTransfer}
      />
      <TopUpPaymentInstructionModal
        isOpen={isOpenPaymentInstruction}
        toggle={() => handleBack({ type: "instruction" })}
        paid={paid}
        onClickPaid={() => {
          onClickPaid();
          togglePaymentInstruction();
        }}
        data={dataAfterTopup}
        title={tLocalTransfer("Payment instruction")}
        t={tLocalTransfer}
      />
      <TopUpManualModal
        isOpen={isOpenProofPayment}
        toggle={toggleProofPayment}
        setAfterSuccessManual={toggle}
      />
    </>
  );

  if (noModal) {
    return (
      <>
        <WrapperModalButtons
          hideButtons={isHideButtons}
          // onClick={() => setSelectedBank(null)}
          childrenButton={isPaymentInstruction && backButton()}
        >
          {desc()}
          {banksComponent()}
        </WrapperModalButtons>
        {OtherModal()}
      </>
    );
  }

  // COMPONENT MAIN RENDER
  return (
    <>
      <RightModal
        isOpen={modal}
        toggle={() =>
          customToggle ? customToggle() : handleClick({ type: "close" })
        }
        array={array}
        width={[420]}
        woTransition={woTransition}
        woClose={woClose}
      />
      {OtherModal()}
    </>
  );
};

export const ProceduresComponent = ({ procedure, index }) => {
  const externalUrl = "https://ibank.bni.co.id";
  const oyIndonesia = "OY Indonesia";
  const companyName = "PT INDOKOALA REMITTANCE";

  let procedureStr = "";
  let procedureBoldStr = "";

  const isExternalUrl = procedure.includes(externalUrl);
  const isBold =
    procedure.includes(oyIndonesia) || procedure.includes(companyName);
  const isBoldOy = procedure.includes(oyIndonesia);
  const isBoldCompany = procedure.includes(companyName);

  if (isExternalUrl) {
    procedureStr = procedure.split(externalUrl);
  }
  if (isBold) {
    if (isBoldOy) {
      procedureBoldStr = procedure.split(oyIndonesia);
    } else {
      procedureBoldStr = procedure.split(companyName);
    }
  } else {
    procedureStr = procedure;
  }

  const RenderProcedure = () => {
    if (isExternalUrl) {
      return (
        <>
          {procedureStr[0] || ""}
          <a
            style={{
              cursor: "pointer",
              textDecorationLine: "underline",
              display: "inline",
            }}
            href={externalUrl}
            target="_blank"
          >
            {externalUrl}
          </a>
          {procedureStr[1] || ""}
        </>
      );
    }
    if (isBold) {
      return (
        <>
          {procedureBoldStr[0] || ""}
          <span style={{ fontFamily: "GothamBold" }}>
            {isBoldOy ? oyIndonesia : companyName}
          </span>
          {procedureBoldStr[1] || ""}
        </>
      );
    }

    return procedureStr;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 8,
      }}
    >
      <div
        style={{
          width: 16,
          minWidth: 16,
          height: 16,
          borderRadius: 11,
          backgroundColor: jackColors.greyE6,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 16,
          overflow: "visible",
        }}
      >
        <GothamMedium style={{ fontSize: 10, color: jackColors.black34 }}>
          {index + 1}
        </GothamMedium>
      </div>
      <h1 style={{ textDecorationLine: "underline" }}></h1>
      <GothamRegular className="font12" style={{ color: colors.grey6c }}>
        {RenderProcedure()}
      </GothamRegular>
    </div>
  );
};

export const ProcedureComponent = ({
  procedure,
  procedures,
  procedureKeys,
  stateObj,
  index,
}) => {
  const { isExpandedIndex, setIsExpandedIndex } = stateObj;
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const styleFlexBetween = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const sameIndex = isExpandedIndex === index;
  const isFirst = index === 0;
  const isLast = index == procedureKeys?.length - 1;
  return (
    <div
      style={{
        paddingBottom: sameIndex && 12,
        borderBottom: !isLast && "1px solid #E6E6E8",
      }}
    >
      <div
        style={{
          ...styleFlexBetween,
          height: 24,
          cursor: "pointer",
          marginTop: isFirst ? 0 : 12,
          marginBottom: sameIndex ? 12 : isLast ? 0 : 12,
        }}
        onClick={() => {
          if (sameIndex) return setIsExpandedIndex(null);
          setIsExpandedIndex(index);
        }}
      >
        <GothamRegular className="font12">
          {tLocalTransfer(procedure)}
        </GothamRegular>
        <JackIcons
          name={sameIndex ? "chevron-up" : "chevron_down"}
          fill={jackColors.black34}
        />
      </div>
      <div>
        {sameIndex &&
          procedures.map((procedure, index) => (
            <ProceduresComponent
              procedure={procedure}
              index={index}
              key={`${procedure}-${index}`}
            />
          ))}
      </div>
    </div>
  );
};
