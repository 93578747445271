import { AES, enc } from "crypto-js";

export const SecretKeyPayload = process.env.NEXT_PUBLIC_ENCRYPT_PAYLOAD;

const base64UrlSafe = (base64?: string) => {
  if (!base64) return "";
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

export const encryptPin = (pin: string | number, secretKey?: string) => {
  if (!secretKey) return pin;
  const encrypted = AES.encrypt(`${pin}`, secretKey).toString();
  const base64 = enc.Base64.stringify(enc.Utf8.parse(encrypted));
  return base64UrlSafe(base64);
};
