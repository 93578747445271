import { yupResolver } from "@hookform/resolvers/yup";
import { noCase } from "change-case";
import { format } from "date-fns";
import countries from "i18n-iso-countries";
import { pickBy, upperCase } from "lodash";
import { useRouter } from "next/router";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useForm as useFormReal } from "react-hook-form";
import { useTranslation } from "react-i18next";
import titleCase from "title-case";
import * as yup from "yup";
import { useWindowDimension } from "../contexts/DimensionContext";
import { expiresFormatter } from "../pageComponents/homeComponents/topup/logic";
import { apiBusiness } from "../tools/api";
// import { combineDateAndTimeToIso } from "../pageComponents/virtualAccountComponents/create/parents";

export const mimePDF = "application/pdf";
export const mimeJPG = "image/jpeg";
export const mimePNG = "image/png";
export const mimeHEIC = "image/heic";

const getDate = (date) => format(new Date(date), "d MMM yyyy");
const substract = (time) => new Date().getTime() - time;

export const dateConstructor = (time) => {
  const today = getDate(new Date());
  const date = getDate(substract(time));
  return `${date} - ${today}`;
};

export const fileSizeString = (number) => {
  const MB = 1024 * 1024;
  const KB = 1024;
  const MBNumber = (Number(number) / MB).toFixed(2);
  const KBNumber = (Number(number) / KB).toFixed(2);

  if (MBNumber > 1) return `${MBNumber} MB`;

  return `${KBNumber} KB`;
};

export const toFormData = (obj, keyFormatter = (key) => key) => {
  const formData = new FormData();
  const keys = Object.keys(obj);
  keys.map((key) => {
    const value = obj[key];
    const isArray = typeof value == "object" && value.length;

    if (isArray)
      return value.map((value) =>
        formData.append(`${keyFormatter(key)}[]`, value)
      );

    if (value) return formData.append(keyFormatter(key), value);
  });
  return formData;
};
export const arraySplitter = (array, divideBy) => {
  const result = [];
  let temp = [];

  array.forEach((button, index) => {
    temp.push(button);
    if (index % divideBy === divideBy - 1 || index === array.length - 1) {
      result.push(temp);
      temp = [];
    }
  });
  return result;
};

export const stringSplitter = (string, splittedBy, joinedBy = "-") => {
  const toArr = arraySplitter((string || "").split(""), splittedBy);
  const toString = toArr.map((letters) => letters.join("")).join(joinedBy);
  return toString;
};

export const schemaGenerator = ({
  stringArr = [],
  objectArr = [],
  arrayArr = [],
  numbersArr = [],
  booleansArr = [],
  filesArr = [],
}) => {
  // EXAMPLE ARRAYS
  // {
  //   stringArr: [{name:'email',yup: yup.email().required()},"full_name", "password", ],
  //   objectArr: ["role", "team"],
  // }

  // stringArr can be just string (name of the field)
  // or an object (must input name and custom yup)

  const defaultText = (field_name) => `${field_name} cannot be empty`;

  const mappedItems = (array, yupType, obj) => {
    array
      .filter((item) => item)
      .map((item) => {
        const defaultYup = (name) => yupType.required(defaultText(name));

        const isObject = typeof item == "object";
        if (!item) return;
        if (isObject) {
          const { name, yup: yupParams, woName } = item;

          if (yupParams) return (obj[name] = yupParams);
          if (woName) return (obj[name] = defaultYup(""));

          return (obj[name] = defaultYup(item));
        }

        return (obj[item] = defaultYup(item));
      });
  };

  let strings = {};
  mappedItems(stringArr, yup.string(), strings);

  let objects = {};
  mappedItems(objectArr, yup.object(), objects);

  let arrays = {};
  mappedItems(arrayArr, yup.object(), arrays);

  let numbers = {};
  mappedItems(numbersArr, yup.number().typeError("must be a number"), numbers);

  let booleans = {};
  mappedItems(booleansArr, yup.boolean(), booleans);

  let files = {};
  mappedItems(filesArr, yup.mixed(), files);

  const result = yupResolver(
    yup.object().shape({
      ...files,
      ...strings,
      ...objects,
      ...arrays,
      ...numbers,
      ...booleans,
    })
  );
  return result;
};

export const usePushQuery = () => {
  const { push, query } = useRouter();

  const deleteQuery = (name) => {
    let obj = query;
    delete obj[name];
    return push({ query: obj });
  };

  const pushQuery = (name, value) => {
    if (!value) return deleteQuery(name);
    let obj = query;
    obj[name] = value;
    return push({ query: obj });
  };

  return { pushQuery };
};

export const canRefetchDecider = (ref, loading) => {
  if (loading) return false;
  const { current } = ref || {};
  if (!current) return false;
  const { scrollTop, scrollHeight, clientHeight } = current;
  const canRefetch = scrollTop + clientHeight > scrollHeight - 20;
  return canRefetch;
};

export const teamManagerTextDecider = ({
  isTeamManager = false,
  text = "",
}) => {
  if (isTeamManager) return `${text} (Team Manager)`;
  return text;
};

export const getUsersFromSpecificTeams = ({ users = [], teams = [] }) => {
  const userTeamIdsRaw = users.map(({ team_id }) => team_id);
  const teamIds = teams.map(({ id }) => id);

  const userTeamIds = userTeamIdsRaw.filter((team_id) =>
    teamIds.includes(team_id)
  );
  const userObjs = users.filter(({ team_id }) => userTeamIds.includes(team_id));
  const userIds = userObjs.map(({ id }) => id);
  return { userTeamIds, userObjs, userIds };
};

export const widthOrHeight = (ref) => {
  const { clientHeight = 0, clientWidth = 0 } = ref?.current || {};
  return { clientHeight, clientWidth };
};

export const arrayToObjByID = (array, id) =>
  (array || []).filter(({ id: itemId }) => itemId == id)[0] || {};

export const windowDimension = () => {
  const dimensions = useWindowDimension();
  return dimensions;
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export const nameInitial = (name) => {
  if (!name) return "";
  const splittedName = String(name).split(" ");
  const firstName = splittedName[0];
  const lastname = splittedName[splittedName.length - 1];

  const firstLetter = String(firstName).charAt(0);
  const secondLetter = String(lastname).charAt(0);

  const isSame = firstName == lastname;
  if (isSame) return upperCase(firstLetter);
  return `${firstLetter}${secondLetter}`;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const aDay = 86400000;
export const aWeek = aDay * 7;
export const aMonth = aDay * 30;

export const dateString = (dateRaw) => {
  const today = new Date();
  const yesterday = new Date(new Date().getTime() - aDay).getTime();
  const thisWeek = new Date(new Date().getTime() - aWeek).getTime();
  const date = new Date(dateRaw);
  const dateTime = date.getTime();

  const isToday =
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear();

  const isYesterday = !isToday && dateTime > yesterday;
  const isThisWeek = !isYesterday && dateTime > thisWeek;

  if (isToday) return "Today";
  if (isYesterday) return "Yesterday";
  if (isThisWeek) return "This Week";
  return customDateFormatter(dateRaw, true, true);
};

const presentDate = new Date().getTime();

export const dateFilterConstructor = (substraction) =>
  new Date(presentDate - substraction).toUTCString();

export const dateFilterConstructorCustom = (date, substraction) => {
  const dateNumber = new Date(date).getTime();
  return new Date(dateNumber - substraction).toUTCString();
};

export const dateOptions = [
  { label: "Today", value: dateFilterConstructor(aDay) },
  { label: "Last 7 Days", value: dateFilterConstructor(aWeek) },
  { label: "Last 30 Days", value: dateFilterConstructor(aMonth) },
];

export const stateOptions = [
  { value: "initial", label: "Created" },
  { value: "approved", label: "Completed" },
  { value: "declined", label: "Declined" },
];

export const flagIso3 = (iso_3) =>
  noCase(countries.alpha3ToAlpha2(upperCase(iso_3 || "")) || "");

export const flagIsoCode = (iso_3) =>
  noCase(countries.numericToAlpha2(iso_3) || "");

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const getCountryName = (iso_2) =>
  countries.getName(iso_2, "en", { select: "alias" });

export const getIso3 = (iso_2) =>
  countries.alpha2ToAlpha3(upperCase(iso_2 || ""));

export const transactionStates = [
  { label: "Created", value: "created" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Submitted", value: "submitted" },
  { label: "Completed", value: "completed" },
  { label: "Rejected", value: "rejected" },
  { label: "Declined", value: "declined" },
  { label: "Canceled", value: "canceled" },
  { label: "Refunded", value: "refunded" },
];

export const virtualAccountStates = [
  { value: "WAITING_PAYMENT", label: "Waiting Payment" },
  { value: "PAYMENT_DETECTED", label: "Payment Detected" },
  { value: "EXPIRED	", label: "Expired" },
  { value: "STATIC_TRX_EXPIRED", label: "Static Trx Expired" },
  { value: "COMPLETE", label: "Complete" },
];

export const incomingPaymentStates = [{ value: "SUCCESS", label: "Success" }];

export const enumOptions = [
  { label: "Advertising expenses", value: "Advertising expenses" },
  { label: "Advisory fees", value: "Advisory fees" },
  { label: "Business insurance", value: "Business insurance" },
  { label: "Construction expenses", value: "Construction expenses" },
  { label: "Delivery fees", value: "Delivery fees" },
  { label: "Education", value: "Education" },
  { label: "Exported goods", value: "Exported goods" },
  { label: "Family support", value: "Family support" },
  { label: "Fund investment", value: "Fund investment" },
  { label: "Gift and donation", value: "Gift and donation" },
  { label: "Hotel accommodation", value: "Hotel accommodation" },
  { label: "Insurance claims", value: "Insurance claims" },
  { label: "Liberalized remittance", value: "Liberalized remittance" },
  { label: "Loan payment", value: "Loan payment" },
  { label: "Maintenance expenses", value: "Maintenance expenses" },
  { label: "Medical treatment", value: "Medical treatment" },
  { label: "Office expenses", value: "Office expenses" },
  { label: "Other fees", value: "Other fees" },
];

export const relationshipOptions = [
  { value: "SELF", label: "Myself" },
  { value: "WIFE", label: "Spouse" },
  { value: "SON", label: "Children" },
  { value: "MOTHER", label: "Parent" },
  { value: "SISTER", label: "Sister" },
  { value: "COUSIN", label: "Cousin" },
  { value: "FRIEND", label: "Friend" },
  { value: "OTHER", label: "Business" },
  { value: "OTHER", label: "Customer" },
  { value: "OTHER", label: "Supplier" },
  { value: "OTHER", label: "Other" },
];

export const sourceOfFundsOptions = [
  { value: "SALARY", label: "Salary/Income" },
  { value: "BUSINESS", label: "Business/Investment" },
  { value: "SAVINGS", label: "Savings" },
  { value: "GIFT", label: "Gift" },
  { value: "OTHER", label: "Loan Facility" },
  { value: "OTHER", label: "Retirement Funds" },
  { value: "OTHER", label: "Third party" },
];

export const removeDuplicates = (array) => {
  const result = array.reduce((acc, current) => {
    const x = acc.find((item) => item === current);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return result;
};

export const deepRemoveDuplicates = (array, key) => {
  const result = (array || []).reduce((acc, current) => {
    const x = acc.find((item) => item?.[key] === current[key]);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return result;
};

export const dataSorter = (data, key) =>
  data.sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0));

export const simpleDataSorter = (data) =>
  data.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

export const calculatorItemsPreparer = (data) =>
  data.map(
    ({ country_iso, country_iso_3, country_name, currency, ...rest }) => ({
      label: titleCase(country_name),
      value: country_iso_3,
      currency,
      country_iso,
      ...rest,
    })
  );

export const newCalculatorItemsPreparer = (data) =>
  data.map((item) => {
    const { country_iso_3, country_name, currency } = item;
    return {
      ...item,
      label: `${titleCase(country_name)}/ ${currency}`,
      value: country_iso_3,
    };
  });

export const toLabelValue = (data) =>
  data.map((item) => {
    const {
      name,
      iso_3,
      code,
      branch_code,
      branch_name,
      id,
      readable_partner_roles,
      label: labelRaw,
      value: valueRaw,
      email,
    } = item;
    const hasBranchCode = Boolean(branch_code);
    const branchNameLabel = hasBranchCode
      ? `${branch_code} - ${titleCase(branch_name)}`
      : "";
    const label =
      email || labelRaw || readable_partner_roles || name || branchNameLabel;
    const value = valueRaw || iso_3 || code || branch_code || id;
    return {
      ...item,
      label,
      value,
    };
  });

export const toLabelValueId = (data, woTitleCase) =>
  data.map(({ name, id, country_name, country_id, ...rest }) => {
    const text = name || country_name || "";
    const label = woTitleCase ? text : titleCase(text);
    const value = id || country_id;
    return {
      label,
      value,
      ...rest,
    };
  });
export const removeNullValues = (obj) => {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  let result = {};
  values.map((item, index) => {
    if (item) {
      result[keys[index]] = item;
    }
  });
  return result;
};

export const formatterForm = (obj) => {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  let result = {};
  values.map((item, index) => {
    const isObject = typeof item == "object";
    if (isObject) {
      return (result[keys[index]] = item.value);
    }
    return (result[keys[index]] = item);
  });
  return result;
};

export const formatCurrency = (string) => {
  if (string) {
    const number = String(string).split(".");
    const decimal = number[1];
    if (decimal)
      if (decimal.length > 1) {
        return numeral(string).format("0,0[.]00");
      } else {
        return numeral(string).format("0,0[.]0");
      }
    else {
      return numeral(string).format("0,0[.]00");
    }
  }
  return string;
};
export const formatCurrencyNoDecimal = (string) =>
  formatCurrency(unformatCurrency(string));

export const unformatCurrency = (amount) => {
  const newAmount = numeral(amount).format("0,0");
  return newAmount.split(",").join("");
};
export const newUnformatCurrency = (amount) => {
  const unformattedAmount = Math.floor(numeral(amount).value());
  const formattedAmount = numeral(unformattedAmount).format("0,0");
  return formattedAmount.split(",").join("");
};

export const unformatCurrencyToPay = (amount) => {
  const unformattedAmount = Math.ceil(numeral(amount).value());
  const formattedAmount = numeral(unformattedAmount).format("0,0");
  return formattedAmount.replace(",", "");
};

export const unformatCurrencyDecimal = (amount) => {
  const newAmount = numeral(amount).format("0,0[.]00");
  return newAmount.split(",").join("");
};
export const formatCurrencyFixed = (string) => {
  if (string) {
    return numeral(string).format("0,0.00");
  }
  return string;
};

export const removePartnerString = (string, payroll) => {
  if (!string) return;
  if (payroll) return titleCase(string.split("payroll_").join(""));
  return titleCase(string.split("partner_").join(""));
};

// export const formatCurrencyNoDecimal = string => {
//   if (string) {
//     return numeral(string).format('0,0');
//   }
//   return string;
// };
export const toNumber = (rawNumber) => Number(unformatCurrency(rawNumber));

export const simpleDate = (date) => {
  if (!date) return "";
  const getDate = new Date(date);
  const to2Digit = (number) => {
    if (Number(number) < 10) return `0${number}`;
    return number;
  };

  const day = to2Digit(getDate.getDate());

  const month = to2Digit(getDate.getMonth() + 1);
  const year = getDate.getFullYear();
  return `${year}-${month}-${day}`;
};

export const customSum = (array) => array.reduce((prev, curr) => prev + curr);

export const dateNow = () => {
  const date = new Date().getDate();
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  return { date, month, year };
};

export const getMonthEN = (number) => {
  let result = "";
  if (number == 1) result = "January";
  if (number == 2) result = "February";
  if (number == 3) result = "March";
  if (number == 4) result = "April";
  if (number == 5) result = "May";
  if (number == 6) result = "June";
  if (number == 7) result = "July";
  if (number == 8) result = "August";
  if (number == 9) result = "September";
  if (number == 10) result = "October";
  if (number == 11) result = "November";
  if (number == 12) result = "December";
  return result;
};

export const getMonthID = (number) => {
  let result = "";
  if (number == 1) result = "Januari";
  if (number == 2) result = "Februari";
  if (number == 3) result = "Maret";
  if (number == 4) result = "April";
  if (number == 5) result = "Mei";
  if (number == 6) result = "Juni";
  if (number == 7) result = "Juli";
  if (number == 8) result = "Agustus";
  if (number == 9) result = "September";
  if (number == 10) result = "Oktober";
  if (number == 11) result = "November";
  if (number == 12) result = "Desember";
  return result;
};

export const getMonthTranslated = (number, t = (item) => item) => {
  let result = "";
  if (number == 1) result = t("January");
  if (number == 2) result = t("February");
  if (number == 3) result = t("March");
  if (number == 4) result = t("April");
  if (number == 5) result = t("May");
  if (number == 6) result = t("June");
  if (number == 7) result = t("July");
  if (number == 8) result = t("August");
  if (number == 9) result = t("September");
  if (number == 10) result = t("October");
  if (number == 11) result = t("November");
  if (number == 12) result = t("December");
  return result;
};

export const getDayName = (numberRaw) => {
  let days = isID
    ? ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]
    : [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
  let d = new Date(numberRaw);
  let dayName = days[d.getDay()];
  return dayName;
};

const monthInBahasa = (value) => {
  if (value === "01") {
    return (value = "Januari");
  } else if (value === "02") {
    return (value = "Februari");
  } else if (value === "03") {
    return (value = "Maret");
  } else if (value === "04") {
    return (value = "April");
  } else if (value === "05") {
    return (value = "Mei");
  } else if (value === "06") {
    return (value = "Juni");
  } else if (value === "07") {
    return (value = "Juli");
  } else if (value === "08") {
    return (value = "Agustus");
  } else if (value === "09") {
    return (value = "September");
  } else if (value === "10") {
    return (value = "Oktober");
  } else if (value === "11") {
    return (value = "November");
  } else {
    return (value = "Desember");
  }
};

export const shortMonth = (value) => {
  if (value === "01" || value == 1) {
    return (value = "Jan");
  } else if (value === "02" || value == 2) {
    return (value = "Feb");
  } else if (value === "03" || value == 3) {
    return (value = "Mar");
  } else if (value === "04" || value == 4) {
    return (value = "Apr");
  } else if (value === "05" || value == 5) {
    if (isID) return (value = "Mei");
    return (value = "May");
  } else if (value === "06" || value == 6) {
    return (value = "Jun");
  } else if (value === "07" || value == 7) {
    return (value = "Jul");
  } else if (value === "08" || value == 8) {
    if (isID) return (value = "Agu");
    return (value = "Aug");
  } else if (value === "09" || value == 9) {
    return (value = "Sep");
  } else if (value === "10" || value == 10) {
    if (isID) return (value = "Oct");
    return (value = "Oct");
  } else if (value === "11" || value == 11) {
    return (value = "Nov");
  } else {
    if (isID) return (value = "Des");
    return (value = "Dec");
  }
};

const with0Decider = (number) => {
  if (String(number).length == 1) return `0${number}`;
  return number;
};

export const startDateFormatter = (dateRaw) => {
  const date = new Date(dateRaw);
  const toStart = date.setUTCHours(-7, 0, 0, 0);
  const result = new Date(new Date(toStart).getTime() + aDay);
  return result;
};

export const endDateFormatter = (dateRaw) => {
  const date = new Date(dateRaw);
  const toEnd = date.setUTCHours(16, 59, 59, 999);
  const result = new Date(new Date(toEnd).getTime() + aDay);
  return result;
};

const timezoneDecider = (date) => {
  const timeDiff = Math.abs(date.getTimezoneOffset()) / 60;
  switch (timeDiff) {
    case 7:
      return "WIB";
    case 8:
      return "WITA";
    case 9:
      return "WIT";
  }
};

export const customDateFormatter = (
  dateRaw,
  isShortMonth = false,
  woClock = false,
  MMddFormat = false,
  getDataRaw = false,
  YYYYMMdd = false,
  withTimezone = false,
  locale = "en"
) => {
  if (!dateRaw) return "";
  const date = new Date(dateRaw);
  const day = date.getDate();
  const monthNumber = date.getMonth();
  const monthIndex = monthNumber + 1;
  const timezoneSuffix = timezoneDecider(date);
  // const { isEnglish } = useLanguage();

  const isEnglish = localStorage.getItem("locale") === "en";
  // const isEN = locale === "en";

  const monthName = isEnglish ? getMonthEN(monthIndex) : getMonthID(monthIndex);

  const month = isShortMonth ? shortMonth(with0Decider(monthIndex)) : monthName;

  const year = date.getFullYear();

  if (YYYYMMdd)
    return `${year}-${
      (monthIndex + "").length === 1 ? `0${monthIndex + ""}` : monthIndex + ""
    }-${(day + "").length === 1 ? `0${day + ""}` : day + ""}`;

  if (getDataRaw) return `${day} ${monthIndex} ${year}`;

  const minutes = with0Decider(date.getMinutes());

  const hour = date.getHours();

  let defaultTime = `${day} ${month} ${year}`;

  if (MMddFormat) defaultTime = `${month} ${day}`;

  if (woClock) return defaultTime;
  const result = `${defaultTime}, ${hour}:${minutes} ${
    withTimezone ? timezoneSuffix : ""
  }`;

  return result;
};

export const dateFormatter = (value) => {
  var newValue = value.split("T");
  var newHour = newValue[1].split(".0");
  var firstDate = newValue[0].split("-");
  var bulan = monthInBahasa(firstDate[1]);
  var newDate = `${firstDate[2]} ${bulan} ${firstDate[0]} ${newHour[0]}`;
  return newDate;
};

export const dateAndHoursFormatter = (value) => {
  if (!value) {
    return value;
  }
  var newValue = value.split("T");
  var newHour = newValue[1].split(".");
  var newDate = `${newValue[0]} ${newHour[0]}`;
  return newDate;
};

export const LongDateFormatter = (value) => {
  if (!value) {
    return value;
  }
  var newValue = value.split("T");
  var newHour = newValue[1].split(".0");
  var firstDate = newValue[0].split("-");
  var bulan = monthInBahasa(firstDate[1]);
  var newDate = `${firstDate[2]} ${bulan} ${firstDate[0]}`;
  return newDate;
};

export const ShortDateFormatter = (value) => {
  if (!value) {
    return value;
  }
  var newValue = value.split("T");
  var newHour = newValue[1].split(".0");
  var firstDate = newValue[0].split("-");
  var bulan = shortMonth(firstDate[1]);
  var newDate = `${firstDate[2]} ${bulan} ${firstDate[0]}`;
  return newDate;
};

export const hourDateGMT = (value) => {
  if (!value) {
    return value;
  }
  var firstSplit = value.split("T");
  var firstGMT = firstSplit[1].split("+");
  var secondGMT = firstGMT[1].split(":");
  var firstHour = firstSplit[1].split(":");
  var newDate = `${firstHour[0]}:${firstHour[1]} (GMT +${secondGMT[0]})`;
  return newDate;
};

export const combineDateAndTimeToIso = (date, time) => {
  const dateObj = new Date(date);
  const [hours, minutes] = time.split(":");
  dateObj.setHours(hours, minutes, 0, 0);

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const hoursFormatted = String(dateObj.getHours()).padStart(2, "0");
  const minutesFormatted = String(dateObj.getMinutes()).padStart(2, "0");
  const seconds = "00";
  const timeZoneOffset = "+07:00";

  return `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}:${seconds}.000${timeZoneOffset}`;
};

export const digitDateFormatter = (dateRaw) => {
  const date = dateRaw || "";
  if (!date) return date;
  const isoDate = new Date(date).toISOString();
  const splittedDate = isoDate.split("-");
  const year = splittedDate[0] || "";
  const month = splittedDate[1] || "";
  const dirtyDay = splittedDate[2] || "";
  const day = dirtyDay.split("T")[0] || "";

  return `${year}/${month}/${day}`;
};

export const digitDateFormatterReversed = (dateRaw, fullYear) => {
  const date = dateRaw || "";
  if (!date) return date;
  const isoDate = new Date(date).toISOString();
  const splittedDate = isoDate.split("-");
  const year = fullYear ? splittedDate[0] : splittedDate[0].substring(2) || "";
  const month = splittedDate[1] || "";
  const dirtyDay = splittedDate[2] || "";
  const day = dirtyDay.split("T")[0] || "";

  return `${day}/${month}/${year}`;
};

export const digitDateFormatterReversedLocal = (
  dateRaw,
  fullYear,
  noLeadingZero
) => {
  const date = dateRaw || "";
  if (!date) return date;
  const timeOnly = dateOnlyGetHour24Format(date);
  const isoDate = combineDateAndTimeToIso(date, timeOnly);
  const splittedDate = isoDate.split("-");
  const year = fullYear ? splittedDate[0] : splittedDate[0].substring(2) || "";
  const month = splittedDate[1] || "";
  const monthWoZero = month[0] == 0 ? month[1] : month;
  // const monthToUse = noLeadingZero ? monthWoZero : month;
  const monthToUse = month; // sementara itu dulu
  const dirtyDay = splittedDate[2] || "";
  const day = dirtyDay.split("T")[0] || "";
  const dayWoZero = day[0] == 0 ? day[1] : day;
  const dayToUse = noLeadingZero ? dayWoZero : day;

  return `${dayToUse}/${monthToUse}/${year}`;
};

export const useForm = (props) => {
  // mode, reValidateMode, resolver, context, defaultValues, shouldFocusError, shouldUnregister, criteriaMode
  const defaultVals = useFormReal(props);

  const { formState, register, watch: watchForm } = defaultVals;

  const watch = (arrOrString, props) => {
    const isArray = Array.isArray(arrOrString);

    if (isArray)
      return pickBy(watchForm(), (_, key) => arrOrString.includes(key));

    return watchForm(arrOrString, props);
  };

  const registerOverride = (props1, props2) => {
    // props1 can be string or object

    const isProps1Obj = typeof props1 == "object";
    if (isProps1Obj) {
      const { name, ...props } = props1;
      return register(name, props);
    }

    return register(props1, props2);
  };
  const newErrors = localizeErrorString(formState);

  return {
    ...defaultVals,
    watch,
    errors: newErrors || formState.errors || {},
    register: registerOverride,
  };
};

const localizeErrorString = (formState) => {
  const { t } = useTranslation("common");
  const { errors } = formState || {};
  const newErrors = { ...errors };
  for (let key in errors) {
    const errorMessage = errors[key]?.message;
    const localizedMessage = t(errorMessage);
    newErrors[key].message = localizedMessage;
  }

  return newErrors;
};

export const digitDateFormatterAndTime = (
  dateRaw = new Date(),
  suffix = "WIB",
  isLocal = true,
  noLeadingZero = false
) => {
  const date = dateRaw || new Date();
  const timeOnly = format(new Date(date), "HH:mm");
  const dateOnly = isLocal
    ? digitDateFormatterReversedLocal(date, true, noLeadingZero)
    : digitDateFormatterReversed(date, true);
  return `${dateOnly} ${timeOnly} ${suffix}`;
  // output: dd/mm/yyyy hh:mm SUFFIX
};

const extraLetter = (number) => {
  const usedNumber = (number % 26) + 96;

  return `${String.fromCharCode(usedNumber)}${String.fromCharCode(usedNumber)}`;
};

export const convertToLetters = (num) => {
  if (typeof num == "number" && num > 26) return extraLetter(num);

  if (num < 1 || num > 26) {
    return "";
  }

  const letterCode = num + 96;
  return String.fromCharCode(letterCode);
};

export const convertToRoman = (num, lowerCase) => {
  const romanNumerals = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };

  let result = "";

  for (let key in romanNumerals) {
    while (num >= romanNumerals[key]) {
      result += key;
      num -= romanNumerals[key];
    }
  }
  return lowerCase ? noCase(result) : result;
};

export const convertToBullet = () => {
  let result = "";
  result = "\u2022";
  return result;
};

export const digitDateWithDigitHourFormatter = (dateRaw) => {
  if (!dateRaw) return dateRaw;
  const splitDate = dateRaw.split("T");
  const getOnlyDate = splitDate[0].split("-");
  const day = getOnlyDate[2] || "";
  const month = getOnlyDate[1] || "";
  const year = getOnlyDate[0] || "";
  const getOnlyTime = splitDate[1].split(".")[0].split(":");
  const hour = getOnlyTime[0] || "";
  const minute = getOnlyTime[1] || "";
  return `${day}/${month}/${year} ${hour}:${minute}`;
  // 2022-03-07T16:36:05.000+07:00
};

export const dateOnlyGetDayAndMonth = (dateRaw, t = (item) => item) => {
  if (!dateRaw) return dateRaw;

  const getFullDate = dateRaw.split("T")[0];
  const getDateRaw = getFullDate.split("-")[2];
  const getDateMonthRaw = shortMonth(getFullDate.split("-")[1]);
  const final = `${getDateRaw} ${t(getDateMonthRaw)}`;
  return final;
  // output : 04 Oct
};

export const dateOnlyGetHour = (dateRaw) => {
  if (!dateRaw) return dateRaw;

  const getFullDate = dateRaw.split("T")[1].split(".")[0];
  const getHour = getFullDate.split(":")[0];
  const getMinute = getFullDate.split(":")[1];
  const text = Number(getHour >= 12) ? "PM" : "AM";
  const final = `${getHour}:${getMinute} ${text}`;
  return final;
  // output : 12:03 PM
};

export const dateOnlyGetHour24Format = (dateRaw) => {
  if (!dateRaw) return dateRaw;
  const date = new Date(dateRaw).toISOString();
  const getFullDate = date.split("T")[1].split(".")[0];
  const getHour = getFullDate.split(":")[0];
  const getMinute = getFullDate.split(":")[1];
  const final = `${getHour}:${getMinute}`;
  return final;
  // output : 12:03
};

export const dateGetHour24FormatLocal = (dateRaw) => {
  if (!dateRaw) return;
  const date = new Date(dateRaw);
  return format(date, "HH:mm");
};

export const isID = (function () {
  if (typeof window === "undefined") return false;
  return localStorage.getItem("locale") === "id";
})();

export const FullDateFormatter = (date, t = (item) => item) => {
  if (!date) return;
  const getNewDate = new Date(date);
  const getName = getDayName(date);
  const getHour = dateOnlyGetHour(date);
  const getDateAndMonth = dateOnlyGetDayAndMonth(date, t);
  const getYear = getNewDate.getFullYear();
  const finalDate = `${t(getName)}, ${getDateAndMonth} ${getYear} ${getHour}`;
  return finalDate;
  // output : Thursday, 22 Dec 2022 00:52 PM
};

export const periodDateFormatter = (date) => {
  // expected input: YYYY-MM-DD
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const leadingZeroMonth =
    month.length == 2 ? String(month) : String(`0${month}`);
  return `${monthInBahasa(leadingZeroMonth)} ${year}`;
};

export const formatBatch = (value) => {
  if (value === "waiting_for_payment") {
    return (value = "waiting payment");
  }
};

export const numberOfFilterQueriesApplied = (query) => {
  const queryKeys = Object.keys(query);
  const bannedQueries = [
    "search_box",
    "page",
    "per_page",
    "isB2C",
    "end_date",
    "id",
    "pageType",
    "from",
    "to",
    "type",
    "page_type_details",
    "page_type",
    "defaultId",
  ];

  const result = queryKeys.filter((key) => {
    const filtered = bannedQueries.includes(key);
    if (filtered) return false;
    const invalidValue = !query[key];
    if (invalidValue) return false;
    return true;
  });

  return result.length;
};

export const dataSeparator = (data, number) => {
  const result = [];
  let temp = [];
  data.forEach((item, index) => {
    temp.push(item);
    if (index % number === number - 1 || index === data.length - 1) {
      result.push(temp);
      temp = [];
    }
  });
  return result;
};

export const submitToLabelValue = (object) => {
  const keys = Object.keys(object || {});
  let obj = {};

  keys.map((key) => {
    const value = object[key];

    const isArray = Array.isArray(value);
    const isObject = typeof value == "object";

    if (isObject && !isArray) {
      const getValue = value?.value;
      if (getValue) return (obj[key] = value?.value);
      return (obj[key] = value);
    }

    return (obj[key] = value);
  });

  return obj;
};

export const numberOfFilteredQueriesForEmptyScreen = (query) =>
  Object.keys(query).length;

export const applyRegexNumbers = (value) => {
  const regex = /[0-9]/g;
  return value.replace(regex, "");
};
export const applyRegexNumbersAndLength = (value) => {
  const regex = /[0-9]{9}/g;
  return value.replace(regex, "");
};

export const applyRegexNumbersAndLength12 = (value) => {
  const regex = /[0-9]{12}/g;
  return value.replace(regex, "");
};

export const regexValidateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const regexValidatePassword = (pass) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return regex.test(String(pass));
};

export const applyRegexLettersAndNumbers = (value) => {
  const regex = /[A-Za-z0-9 \.\-]/g;
  return value.replace(regex, "");
};

export const applyRegexLetters = (value) => {
  const regex = /[A-Za-z \.\-]/g;
  return value.replace(regex, "");
};

export const applyRegexName = (value) => {
  const regex = /[A-Za-z\.\,\'\-]*(-| )*[A-Za-z\.\,\'\-]/g; // allows only alphabets, specific symbols, and hyphen or space when in between words
  return value.replace(regex, "");
};

export const appyRegexSymbols = (value) => {
  const regex = /[^\w\s]|_/g;
  return value.replace(regex, "");
};

export const applyRegexLettersAndSymbols = (value) => {
  const regex = /^\D+$/g;
  return value.replace(regex, "");
};

export const applyRegexOnlyNumbers = (value) => {
  const regex = /[^\d]/g;
  return value.replace(regex, "");
};

export const applyRegexOnlyAlphabet = (value) => {
  const regex = /[^A-Za-z\s]/g;
  return value.replace(regex, "");
};

export const applyRegexAlphanumeric = (value) => {
  const regex = /[^A-Za-z0-9\s]+/g;
  return value.replace(regex, "");
};

export const applyRegexAlphanumericSpecificSymbol = (value) => {
  // symbol @ . _
  const regex = /[^A-Za-z0-9@._\s]+/g;
  return value.replace(regex, "");
};

export const applyRegexAlphanumericAndDash = (value) => {
  // Regex to keep only alphanumeric characters and dash
  const regex = /[^A-Za-z0-9-\s]+/g;
  return value.replace(regex, "");
};

export const applyRegexAlphanumericWithoutSymbols = (value) => {
  const regex = /[^A-Za-z0-9]+/g;
  return value.replace(regex, "");
};

export const applyRegexNotes = (value) => {
  // accept only this character [0-9 A-Z / -]
  const regex = /[^\w\s/-]/g;
  return value.replace(regex, "");
};

export const applyRegexWhiteSpaces = (value) => {
  return value?.replace(/\s+/g, " ");
};

export const regexValidateHexColors = (value) => {
  const regex = /^#[0-9a-f]{3,6}$/i;
  return regex.test(String(value));
};

export const containsOnlyDigits = (value) => {
  return /^\d+$/.test(value);
};

export const containsOnlyAlphaNumeric = (value) => {
  const isAlphaNumericOrSpace = /^[A-Za-z0-9 ]+$/.test(value);
  return !isAlphaNumericOrSpace;
};

export const groupAlphabetDeep = (data, string) => {
  const reduceData = data.reduce((r, e) => {
    let group = (e[string][0] || "").toUpperCase();
    if (!r[group]) r[group] = { group, items: [e] };
    else r[group].items.push(e);
    return r;
  }, {});
  let result = Object.values(reduceData);
  return result;
};

export const rearrangeDatesDescending = (array) => {
  array.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return array;
};

export const sectionListDateFormatter = (array, key = "created_at") => {
  const dateArray = (array || []).map((item) => {
    const date = item[key];
    const dateSection = dateString(date);
    return { ...item, dateSection };
  });

  const data = deepRemoveDuplicates(dateArray, "dateSection").map(
    (item) => item["dateSection"]
  );

  const result = data.map((date) => {
    const data = dateArray.filter((item) => date == item.dateSection);
    return { date, data };
  });
  return result;
};

export const toBooleanQuery = (string) => {
  if (string == "false") {
    return false;
  }
  if (string == "true") {
    return true;
  }
  return false;
};

export const asyncDownload = async ({ url, filename, handleError }) => {
  try {
    const { data } = await apiBusiness.get(url, {
      responseType: "blob",
    });
    const file = new Blob([data], {
      type: `application/pdf`,
    });
    const targetUrl = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = targetUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode && link.parentNode.removeChild(link);
  } catch (err) {
    handleError && handleError(err);
    console.log(err);
  }
};

export const stringCutter = (string, length = 20) =>
  string?.length > length ? `${string.substring(0, length)}...` : string;

export const isProduction =
  process.env.NEXT_PUBLIC_TRANSFEZ_APP_ENV == "PRODUCTION";

export const phoneCodeFunc = (array) => {
  const result = array.map((item) => {
    const { value: valueRaw, label: countryName, code: codeRaw } = item;
    const label = valueRaw;
    const value = codeRaw;
    const code = valueRaw;
    const country_iso = codeRaw;
    return { ...item, label, value, code, country_iso, countryName };
  });
  const sortedData = dataSorter(result, "code");
  return sortedData;
};

export const asyncTimeout = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const useExpiredCountDownDetails = ({ payment_expiry_at }) => {
  const time = expiresFormatter(payment_expiry_at);
  const expiredIn = FullDateFormatter(payment_expiry_at || "");
  const dataForCountDown = { expiredIn };
  const isExpiredTime = new Date() > new Date(time);

  return {
    time,
    dataForCountDown,
    isExpiredTime,
  };
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999; path=/`;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const cookiesArray = document.cookie.split(";");
  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i];
    while (cookie.charAt(0) === " ")
      cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
};
export const setCookie = (name, value, minutes = 5) => {
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000); // Expiry time in minutes
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};
