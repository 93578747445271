import axios, { AxiosInstance, AxiosResponse, CancelTokenSource } from "axios";
import { ToasterHook } from "contexts/ToasterContext";
import React, { useRef, useState } from "react";
import { useFingerprinting } from "tools/api/functions/fingerprinting";

const containsUnsafeString = (obj: {
  pin?: string;
  data?: {
    [key: string]: any;
  };
  new_pin?: string;
  [key: string]: any;
}) => {
  const jsonString = String(JSON.stringify(obj)).toLowerCase();
  const scriptTagPattern =
    /<\s*script\b[^>]*>([\s\S]*?)<\/\s*script\s*>|<\s*script\b[^>]*\/?\s*>/i;

  const result = scriptTagPattern.test(jsonString);

  return result;
};
export interface UseMutationParams<R> {
  type?: string;
  defaultValue?: R;
  method?: "put" | "post" | "patch" | "delete";
  url: string;
  resultFormatter?: (data: any, prev?: R, payload?: any) => R;
  afterSuccess?: (
    result: R,
    payload: any,
    response: AxiosResponse<any>,
    value: any
  ) => Promise<void> | void;
  withError?: boolean;
  handleError?: (
    error: any,
    setResult: React.Dispatch<React.SetStateAction<R>>
  ) => void;
  defaultLoading?: boolean;
  beforeApiCall?: (payload: any) => Promise<void> | void;
}

interface UseMutationParamsLocal<R> extends UseMutationParams<R> {
  strapi: AxiosInstance;
  apiBusinessLong: AxiosInstance;
  apiBusinessV2: AxiosInstance;
  apiBusiness: AxiosInstance;
}

export const useMutationRaw = <
  P extends Record<string, any> = Record<string, any>,
  R = any
>({
  type,
  defaultValue = {} as R,
  method = "put",
  url,
  resultFormatter = (data) => data as R,
  afterSuccess,
  withError = true,
  handleError,
  defaultLoading = false,
  beforeApiCall = () => {},
  strapi,
  apiBusinessLong,
  apiBusinessV2,
  apiBusiness,
}: UseMutationParamsLocal<R>) => {
  const apiDecider = (type?: string) => {
    if (type == "axios") return axios;
    if (type == "strapi") return strapi;
    if (type == "long") return apiBusinessLong;
    if (type == "v2") return apiBusinessV2;
    return apiBusiness;
  };
  const [loading, setLoading] = useState(defaultLoading);
  const [result, setResult] = useState<R>(defaultValue);

  const { errorToasterApi, errorToaster } = ToasterHook();

  const { format } = useFingerprinting();

  const ref = useRef<CancelTokenSource | null>(null);

  const mutation = async (value: P) => {
    const cancelTokenSource = axios.CancelToken.source();

    if (ref.current) {
      ref.current.cancel();
    }

    ref.current = cancelTokenSource;

    try {
      setLoading(true);

      const isScript = containsUnsafeString(value);

      if (isScript) return errorToaster("", "Internal server error (420)");

      const payloadMutation = await format(value);

      const api = apiDecider(type);
      await beforeApiCall(payloadMutation);
      const response = await api[method](url, payloadMutation, {
        cancelToken: cancelTokenSource.token,
      });
      const result = resultFormatter(response);

      setResult((prev) => resultFormatter(response, prev, value));

      if (typeof afterSuccess === "function") {
        await afterSuccess(result, value, response, value);
      }
    } catch (error) {
      if (axios.isCancel(error)) return;
      handleError && handleError(error, setResult);

      if (withError) errorToasterApi(error);
    } finally {
      setLoading(false);
      ref.current = null;
    }
  };

  return { loading, mutation, result, setResult };
};
