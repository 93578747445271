import { encryptPin } from "tools/api/functions/hooks/mutation/payload/hash";

interface Payload {
  [key: string]: any;
}

export const encryptedKeysFP = (obj?: Payload) => {
  const keys = Object.keys(obj || {}).filter(
    (key) => key.includes("password") || key.includes("pin")
  );
  return { keys, isEncrypted: !!keys.length };
};

export const payloadFormatterFP = (
  payloadRaw: Payload,
  secretKey?: string
): Payload => {
  let payload = payloadRaw || {};

  const { data } = payload || {};

  const { pin } = data || {};

  const getKeys = (obj: Payload) => {
    let result = obj;

    const { keys } = encryptedKeysFP(result);

    keys.forEach((key) => {
      const value = result[key];
      result[key] = encryptPin(value, secretKey);
    });

    return result;
  };

  if (pin) {
    payload["data"] = { ...getKeys(data), is_encrypted: !!secretKey };
  } else {
    payload = getKeys(payload);
  }

  return { ...payload, is_encrypted: !!secretKey };
};
