import { deepRemoveDuplicates, removeDuplicates, toLabelValue } from "@tools";
import { getUserRole, useGetAuth } from "contexts/AuthContext";
import { isEmpty, upperCase } from "lodash";
import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFetch } from "tools/api";
import logoBri from "../../assets/images/bank-bri-icon.svg";
import logoMandiri from "../../assets/images/bank-mandiri-icon.svg";
import logoCimb from "../../assets/images/bank-cimb-niaga-icon.svg";
import logoBtpn from "../../assets/images/bank-btpn-icon.svg";
import logoPermata from "../../assets/images/bank-permata-icon.svg";
import logoBni from "../../assets/images/bank-bni-icon.svg";
import logoBsi from "../../assets/images/bank-bsi-icon.svg";
import logoBca from "../../assets/images/bank-bca-icon.svg";
import logoDanamon from "../../assets/images/bank-danamon-icon.svg";
import { useModalHook } from "components/Modals";
import { useSmoothRightModal } from "components/Modals/RightModal/parent";
import { FetchParams } from "tools/api/functions/hooks/fetch";

const BANK_BRI = "Bank BRI";
const BANK_MANDIRI = "Bank Mandiri";
const BANK_CIMB = "Bank CIMB Niaga";
const BANK_PERMATA = "Bank Permata";
const BANK_BNI = "Bank BNI";
const BANK_BTPN = "Bank BTPN";
const BANK_BCA = "Bank BCA";
const BANK_BSI = "Bank BSI";
const BANK_DANAMON = "Bank Danamon";

type GeneralBank =
  | "Bank BRI"
  | "Bank Mandiri"
  | "Bank CIMB Niaga"
  | "Bank Permata"
  | "Bank BNI"
  | "Bank BTPN"
  | "Bank BCA"
  | "Bank BSI"
  | "Bank Danamon";

export type TeamJack = {
  id?: number;
  name?: string;
  label?: string;
  [other: string]: unknown;
};
export type UserJack = {
  id?: number;
  name?: string;
  email?: string;
  team_id?: number | null;
  team?: TeamJack;
  isDeleted?: boolean;
  [other: string]: unknown;
};
export type CategoryJack = {
  id?: number;
  name?: string;
  created_at?: string;
  [other: string]: unknown;
};
export type LocalTransferBank = {
  id?: number;
  full_name?: string;
  swift?: string;
  name?: string;
  alias?: string;
  bank_name?: string;
  [other: string]: unknown;
};
type LocalBank = {
  id?: number;
  full_name?: string;
  swift?: string;
  name?: string;
  alias?: string;
  [other: string]: unknown;
};
type SGBank = {
  id?: number;
  bank?: string;
  swift_code?: string;
  [other: string]: unknown;
};
type RoleJack = {
  id?: number;
  name?: string;
  [other: string]: unknown;
};
type VABankRaw = {
  id?: number;
  name?: GeneralBank;
  code?: string;
  min_amount?: number;
  max_amount?: number;
  config?: string;
  fee?: number;
  [other: string]: unknown;
};
type VABank = {
  label?: string;
  code?: string;
  value?: string;
  closed_min_amount?: number;
  closed_max_amount?: number;
  payment_config?: string;
  icon?: string;
  iconSize?: { width: string; height: string; objectFit?: "contain" };
  fee?: number;
  iconColor?: string;
};
type CounterActivity = number;

type TeamJackFetchData = {
  data?: TeamJack[];
};
type UserJackFetchData = {
  data?: UserJack[];
};
type CategoryFetchData = {
  data?: CategoryJack[];
};
export type LocalTransferBankFetchData = {
  data?: LocalTransferBank[];
};
type LocalBankFetchData = {
  data?: LocalBank[];
};
type VABankFetchData = {
  data?: VABankRaw[];
};
type CounterActivityFetchData = {
  total_unread_notifications?: number | null;
  total_all_unread_notifications?: number | null;
};

type ChangeUserDataArgs = {
  id?: number | null;
  userData?: UserJack;
};
type AddUsersArgs = {
  newUsers?: UserJack[];
};
type RemoveUserArgs = {
  id?: number | null;
};
type RemoveTeamFromUsers = {
  teamId?: number | null;
};
type AddUsersWithTeam = {
  teamId?: number | null;
  newUsers?: UserJack[];
};
type AddTeamWithUsers = {
  newTeam?: TeamJack | null;
  newUsers?: UserJack[];
};

type TourDummyData = {
  originator_type?: string;
  created_at?: string;
  title_eng?: string;
  content_eng?: string;
  is_read?: boolean;
  navigation_type?: string;
};

type ConstantsValue = {
  users?: UserJack[];
  allUsers?: UserJack[];
  deletedUsers?: UserJack[];
  requestedUsers?: UserJack[];
  notFoundDeletedUserIds?: number[];
  setNotFoundDeletedUserIds?: Dispatch<SetStateAction<number[]>>;
  injectDeletedUsers?: (deletedUsers: UserJack[]) => void;
  loadingUsers?: boolean;
  refetchUsers?: () => void;
  loadingRequestedUsers?: boolean;
  refetchRequestedUsers?: () => void;
  changeUserData?: (args: ChangeUserDataArgs) => void;
  addUsers?: (args: AddUsersArgs) => void;
  removeUser?: (args: RemoveUserArgs) => void;
  removeTeamFromUsers?: (args: RemoveTeamFromUsers) => void;
  addUsersWithTeam?: (args: AddUsersWithTeam) => void;
  addTeamWithUsers?: (args: AddTeamWithUsers) => void;
  teamsData?: TeamJack[];
  teamsLoading?: boolean;
  refetchTeamsData?: () => void;
  rolesData?: RoleJack[];
  rolesLoading?: boolean;
  sgBanks?: SGBank[];
  sgBanksLoading?: boolean;
  idBanks?: LocalBank[];
  idBanksLoading?: boolean;
  localTransferBanks?: LocalTransferBank[];
  loadingTransferBanks?: boolean;
  categories?: CategoryJack[];
  loadingCategories?: boolean;
  loadingDeletedCategories?: boolean;
  refetchCategories?: () => void;
  refetchDeletedCategories?: () => void;
  vaBankOptions?: VABank[];
  loadingVaBankOptions?: boolean;
  refetchVaBankOptions?: () => void;
  counterActivity?: CounterActivity;
  refetchActivity?: () => void;
  counterNews?: CounterActivity;
  refetchNews?: () => void;
  counterTotal?: CounterActivity;
  refetchTotal?: () => void;
  notifModalRef?: MutableRefObject<undefined>;
  tourRunning?: boolean;
  setTourRunning?: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
  toggle?: () => void;
  close?: () => void;
  tourRunningDummyData?: TourDummyData[];
  setTourRunningDummyData?: Dispatch<SetStateAction<TourDummyData[]>>;
  toToggle?: () => void;
  toListener?: () => void;
  setDataTotal?: Dispatch<SetStateAction<CounterActivity>>;
  showCounter?: boolean;
  setShowCounter?: Dispatch<SetStateAction<boolean>>;
  totalUnread?: number;
};

const defaultFormatter = (data: unknown) =>
  toLabelValue((data as unknown as { data?: unknown })?.data || []);

const ConstantsContext = createContext<ConstantsValue>({});

interface FetchParamsExtended<T> extends FetchParams<T> {
  woAuth?: boolean;
  canRun?: boolean;
}

export const useFetcher = <T,>({
  url,
  formatter = (data: any) => data as T,
  canRun = true,
  woAuth = false,
  ...rest
}: FetchParamsExtended<T>) => {
  const { pathname } = useRouter();
  const { user } = useGetAuth();

  const { data, loading, setData, refetch } = useFetch<T>({
    url,
    formatter,
    woInit: true,
    noToaster: true,
    ...rest,
  });

  const isArrayAndHasLength = Array.isArray(data) && data.length > 0;

  useEffect(() => {
    if (isEmpty(user) && url === "/teams") return setData([] as T);
    if (isEmpty(user) && !woAuth) return setData([] as T);
    if (isArrayAndHasLength) return;
    if (!canRun) return;
    refetch();
  }, [isArrayAndHasLength, pathname, isEmpty(user), woAuth]);

  return { data, setData, loading, refetch };
};

export const ConstantsProvider = ({ children }: { children?: ReactNode }) => {
  const [deletedUsers, setDeletedUsers] = useState<UserJack[]>([]);
  const [notFoundDeletedUserIds, setNotFoundDeletedUserIds] = useState<
    number[]
  >([]);

  const injectDeletedUsers = (deletedUsers: UserJack[]) => {
    setDeletedUsers((prev) => [...prev, ...deletedUsers]);
  };

  const { user } = useGetAuth();

  const isCompanyVerified =
    (user as { partner?: { document_state?: string } })?.partner
      ?.document_state === "verified";

  const { isEmployee } = getUserRole();

  const {
    data: teamsData,
    loading: teamsLoading,
    refetch: refetchTeamsData,
    setData: setTeams,
  } = useFetcher<TeamJack[]>({
    url: "/teams",
    woAuth: true,
    formatter: defaultFormatter,
  });

  const {
    data: teamsData2,
    loading: teamsLoading2,
    refetch: refetchTeamsData2,
    setData: setTeams2,
  } = useFetch<TeamJack[]>({
    url: "/teams",
    formatter: (data) => {
      return data?.data ?? [];
    },
  });

  const {
    data: users,
    loading: loadingUsers,
    refetch: refetchUsers,
    setData: setUsers,
  } = useFetcher<UserJack[]>({
    url: "/business_users/active_users",
    type: "long",
    formatter: defaultFormatter,
  });

  const {
    data: requestedUsers,
    loading: loadingRequestedUsers,
    refetch: refetchRequestedUsers,
  } = useFetcher<UserJack[]>({
    url: "/business_users/invitation_requests",
    formatter: defaultFormatter,
  });

  const allUsers = [...(users ?? []), ...deletedUsers];

  const changeUserData = ({ id = null, userData = {} }: ChangeUserDataArgs) => {
    const userIndex = users?.findIndex(
      ({ id: userId }) => Number(userId) === Number(id)
    );
    if (userIndex == null) return;
    const tempUsers = [...(users ?? [])];
    const userTeam = teamsData?.find(
      ({ id }) => Number(id) === Number(userData?.team_id)
    );
    if (Boolean(userTeam)) userData.team = userTeam;
    tempUsers[userIndex] = userData;
    setUsers(tempUsers);
  };

  const addUsers = ({ newUsers = [] }: AddUsersArgs) => {
    setUsers(
      deepRemoveDuplicates([...(users ?? []), ...newUsers], "id") as UserJack[]
    );
  };

  const addUsersWithTeam = ({
    teamId = null,
    newUsers = [],
  }: AddUsersWithTeam) => {
    const team = teamsData?.find(({ id }) => Number(id) === Number(teamId));
    if (!team) return;
    const tempUsers = deepRemoveDuplicates(
      [...(users ?? []), ...newUsers],
      "id"
    ) as UserJack[];
    const newUserIds = newUsers?.map((newUser) => Number(newUser?.id));
    tempUsers?.forEach((user, index) => {
      if (!newUserIds?.includes(Number(user?.id))) return user;
      const updatedUser = { ...user, team_id: Number(teamId), team };
      tempUsers[index] = updatedUser;
    });
    setUsers(tempUsers);
  };

  const addTeamWithUsers = ({
    newTeam = null,
    newUsers = [],
  }: AddTeamWithUsers) => {
    const tempTeams = [...(teamsData ?? [])];
    if (newTeam != null) tempTeams.push(newTeam);
    const team = tempTeams?.find(
      ({ id }) => Number(id) === Number(newTeam?.id)
    );
    if (!team) return;
    const tempUsers = deepRemoveDuplicates(
      [...(users ?? []), ...newUsers],
      "id"
    ) as UserJack[];
    const newUserIds = newUsers?.map((newUser) => Number(newUser?.id));
    tempUsers?.forEach((user, index) => {
      if (!newUserIds?.includes(Number(user?.id))) return user;
      const updatedUser = { ...user, team_id: Number(newTeam?.id), team };
      tempUsers[index] = updatedUser;
    });
    setTeams(tempTeams);
    setUsers(tempUsers);
  };

  const removeUser = ({ id = null }: RemoveUserArgs) => {
    const filterer = (isFindDeletedUser = false) => {
      return (users ?? [])?.filter(({ id: userId }) => {
        if (isFindDeletedUser) return Number(userId) === Number(id);
        return Number(userId) !== Number(id);
      });
    };
    setUsers(filterer());
    setDeletedUsers((prev) => [...prev, ...filterer(true)]);
  };

  const removeTeamFromUsers = ({ teamId = null }: RemoveTeamFromUsers) => {
    const tempUsers = [...(users ?? [])];
    tempUsers?.forEach((user, index) => {
      if (Number(user?.team_id) !== Number(teamId)) return user;
      delete user.team;
      tempUsers[index] = { ...user, team_id: null };
    });
    setUsers(tempUsers);
  };

  const { data: rolesData, loading: rolesLoading } = useFetcher<RoleJack[]>({
    url: "/business_roles?q[name_cont]=partner_",
    formatter: (data) => toLabelValue(data?.data || []),
  });
  const { data: sgBanks, loading: sgBanksLoading } = useFetcher<SGBank[]>({
    url: "/swift-codes?country_code=SG&_limit=10000",
    type: "strapi",
    formatter: (data: SGBank[]) => {
      const requiredArrays = data.filter(
        ({ swift_code }) => String(swift_code ?? "").length == 8
      );
      const removeDuplicate: SGBank[] = deepRemoveDuplicates(
        requiredArrays,
        "bank"
      );
      const result = removeDuplicate.map((item) => {
        const { bank: bank_name, id: value } = item;
        const label = upperCase(bank_name);
        return { ...item, bank_name, label, value };
      });
      return result;
    },
  });

  const { data: idBanks, loading: idBanksLoading } = useFetcher<LocalBank[]>({
    url: "/local_banks",
    formatter: (data: LocalBankFetchData) => {
      const array = data?.data || [];
      const result = array.map((item) => {
        const {
          full_name: bank_name,
          swift: swift_code,
          name,
          id: value,
          alias,
        } = item;
        const label = alias ? upperCase(alias) : upperCase(name);
        return { ...item, value, bank_name, swift_code, label };
      });
      return result;
    },
  });
  const { data: localTransferBanks, loading: loadingTransferBanks } =
    useFetcher<LocalTransferBank[]>({
      url: "/local_transaction_batches/payers",
      formatter: (data: LocalTransferBankFetchData) => {
        const array = data?.data || [];
        const result = array?.map((item) => {
          const {
            full_name: bank_name,
            swift: swift_code,
            name,
            id: value,
            alias,
          } = item;
          const label = alias ? upperCase(alias) : upperCase(name);
          return { ...item, value, bank_name, swift_code, label };
        });
        return result;
      },
    });
  const {
    data: activeCategories,
    loading: loadingActiveCategories,
    refetch: refetchActiveCategories,
  } = useFetcher<CategoryJack[]>({
    url: "/transaction_categories",
    formatter: (data: CategoryFetchData) => data?.data ?? [],
  });
  const {
    data: deletedCategories,
    loading: loadingDeletedCategories,
    refetch: refetchDeletedCategories,
  } = useFetcher<CategoryJack[]>({
    url: "/transaction_categories?index=deleted",
    formatter: (data: CategoryFetchData) => {
      return data?.data ?? [];
    },
  });

  (activeCategories ?? []).sort((a, b) =>
    (a?.created_at ?? "") > (b?.created_at ?? "")
      ? -1
      : (a?.created_at ?? "") < (b?.created_at ?? "")
      ? 1
      : 0
  );

  const loadingCategories = loadingActiveCategories;
  const categories: CategoryJack[] = [
    ...(activeCategories ?? []),
    ...(deletedCategories ?? []).map((deletedCategory) => ({
      ...deletedCategory,
      isDeleted: true,
    })),
  ];

  const teamsDataNames = (teamsData ?? []).map(
    (teamData) => teamData?.label ?? ""
  );

  const {
    data: vaBankOptions,
    loading: loadingVaBankOptions,
    refetch: refetchVaBankOptions,
  } = useFetcher<VABank[]>({
    url: "/collection_va_banks",
    formatter: (res: VABankFetchData) => {
      const data = res?.data;

      const bankIcon = {
        [BANK_BRI]: logoBri,
        [BANK_MANDIRI]: logoMandiri,
        [BANK_CIMB]: logoCimb,
        [BANK_PERMATA]: logoPermata,
        [BANK_BNI]: logoBni,
        [BANK_BTPN]: logoBtpn,
        [BANK_BCA]: logoBca,
        [BANK_BSI]: logoBsi,
        [BANK_DANAMON]: logoDanamon,
      };

      const icon16 = { width: "16px", height: "16px" };
      const icon20 = { width: "20px", height: "20px" };

      const iconSize = {
        [BANK_BRI]: icon16,
        [BANK_MANDIRI]: icon20,
        [BANK_CIMB]: icon20,
        [BANK_PERMATA]: icon20,
        [BANK_BNI]: icon16,
        [BANK_BTPN]: icon16,
        [BANK_BCA]: icon16,
        [BANK_BSI]: icon20,
        [BANK_DANAMON]: { ...icon20, objectFit: "contain" },
      };

      const iconColor = {
        [BANK_BRI]: "#003399",
        [BANK_MANDIRI]: "#FFC300",
        [BANK_CIMB]: "#7B0100",
        [BANK_PERMATA]: "#009247",
        [BANK_BNI]: "#FF6600",
        [BANK_BTPN]: "#FF9900",
        [BANK_BCA]: "#0070BB",
        [BANK_BSI]: "#00A94F",
        [BANK_DANAMON]: "#F36F25",
      };

      const bankOptions = (data ?? []).map((bank) => {
        return {
          label: bank.name,
          code: bank.code,
          value: bank.name,
          closed_min_amount: bank.min_amount,
          closed_max_amount: bank.max_amount,
          payment_config: bank.config,
          icon: bank?.name ? bankIcon[bank.name] : null,
          iconSize: bank?.name ? iconSize[bank.name] : icon16,
          fee: bank.fee,
          iconColor: bank?.name ? iconColor[bank.name] : null,
        } as VABank;
      });

      const bankOptionsSorted = bankOptions.sort((a, b) => {
        const labelA = a?.label?.toLowerCase() ?? "";
        const labelB = b?.label?.toLowerCase() ?? "";
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });

      return bankOptionsSorted;
    },
    canRun: isCompanyVerified && !isEmployee,
  });

  const financeIndex = teamsDataNames.findIndex((teamDataName) =>
    teamDataName.toLowerCase().includes("financ")
  );
  const teamsDataFinance = (teamsData ?? []).filter(
    (_, index) => index === financeIndex
  );
  const teamsDataWoFinance = (teamsData ?? []).filter(
    (_, index) => index !== financeIndex
  );

  const teamsDataOutput = [...teamsDataFinance, ...teamsDataWoFinance];

  const newRolesData = (rolesData ?? []).map((item) => {
    const { name } = item || {};
    if (name == "partner_super_admin")
      return { ...item, label: "Business Owner" };
    return item;
  });

  const [totalUnread, setTotalUnread] = useState(0);
  const [showCounter, setShowCounter] = useState(true);

  const { data: counterActivity, refetch: refetchActivity } =
    useFetch<CounterActivity>({
      url: "/custom_notifications?per_page=1&q[notification_tab_eq]=activity",
      formatter: (data) => {
        const unread = data?.total_unread_notifications ?? 0;
        return unread;
      },
    });

  const { data: counterNews, refetch: refetchNews } = useFetch<CounterActivity>(
    {
      url: "/custom_notifications?per_page=1&q[notification_tab_eq]=news_and_promo",
      formatter: (res) => {
        const unread = res?.total_unread_notifications ?? 0;
        return unread;
      },
    }
  );

  const {
    data: counterTotal,
    setData: setDataTotal,
    refetch: refetchTotal,
  } = useFetch<CounterActivity>({
    url: "/custom_notifications?per_page=1",
    formatter: (res) => {
      const unread = res?.total_all_unread_notifications ?? 0;
      return unread;
    },
  });

  useEffect(() => {
    if ((counterTotal ?? 0) > totalUnread) {
      setTotalUnread(counterTotal ?? 0);
      setShowCounter(true);
    }
  }, [counterTotal]);

  const notifModalRef = useRef();
  const [tourRunning, setTourRunning] = useState(false);
  const { isOpen, toggle, close } = useModalHook();
  const { toToggle, toListener } = useSmoothRightModal();

  const [tourRunningDummyData, setTourRunningDummyData] = useState<
    TourDummyData[]
  >([
    {
      originator_type: "general",
      created_at: "2023-06-13T10:29:51.000+07:00",
      title_eng: "Welcome to Jack! 🎉",
      content_eng:
        "Jack is ready to support every step of your company’s finance management journey",
      is_read: false,
      navigation_type: "transactions",
    },
  ]);

  return (
    <ConstantsContext.Provider
      value={{
        users,
        allUsers,
        deletedUsers,
        injectDeletedUsers,
        notFoundDeletedUserIds,
        setNotFoundDeletedUserIds,
        loadingUsers,
        refetchUsers,
        requestedUsers,
        loadingRequestedUsers,
        refetchRequestedUsers,
        changeUserData,
        addUsers,
        removeUser,
        removeTeamFromUsers,
        addUsersWithTeam,
        addTeamWithUsers,
        teamsData: teamsDataOutput,
        teamsLoading,
        refetchTeamsData,
        rolesData: newRolesData,
        rolesLoading,
        sgBanks,
        sgBanksLoading,
        idBanks,
        idBanksLoading,
        localTransferBanks, //: deepRemoveDuplicates(localTransferBanks, "label"),
        loadingTransferBanks,
        categories,
        loadingCategories,
        loadingDeletedCategories,
        refetchCategories: refetchActiveCategories,
        refetchDeletedCategories,
        vaBankOptions,
        loadingVaBankOptions,
        refetchVaBankOptions,
        counterActivity: tourRunning ? 0 : counterActivity,
        refetchActivity,
        counterNews: tourRunning ? 1 : counterNews,
        refetchNews,
        counterTotal: tourRunning ? 1 : counterTotal,
        refetchTotal,
        notifModalRef,
        tourRunning,
        setTourRunning,
        isOpen,
        toggle,
        close,
        tourRunningDummyData,
        setTourRunningDummyData,
        toToggle,
        toListener,
        setDataTotal: setDataTotal as Dispatch<SetStateAction<number>>,
        showCounter,
        setShowCounter,
        totalUnread,
      }}
    >
      {children}
    </ConstantsContext.Provider>
  );
};

export const useConstants = () => {
  const constantsValue = useContext(ConstantsContext);
  return constantsValue;
};

export const useInjectDeletedUsers = ({
  userIds = [],
}: {
  userIds?: UserJack["id"][];
}) => {
  const {
    users,
    deletedUsers,
    notFoundDeletedUserIds,
    loadingUsers,
    injectDeletedUsers,
    setNotFoundDeletedUserIds,
  } = useConstants();

  const deletedUserIds = deletedUsers?.map(({ id }) => Number(id));

  const possiblyDeletedUserIds: UserJack["id"][] = [];
  userIds?.forEach((userId) => {
    const isActiveUser = users?.find(({ id }) => id === userId);
    const isValid = userId != null;
    const isKnownToNotBeFound = notFoundDeletedUserIds?.find(
      (notFoundDeletedUserId) => notFoundDeletedUserId === userId
    );
    if (!isActiveUser && isValid && !isKnownToNotBeFound) {
      possiblyDeletedUserIds.push(userId);
    }
  });

  const newDeletedUserIds = removeDuplicates(
    possiblyDeletedUserIds?.filter(
      (id) => !deletedUserIds?.includes(Number(id))
    )
  ) as unknown as number[];

  const urlBuilder = () => {
    let url = "/business_users/deleted_users?";
    newDeletedUserIds?.forEach((userId, index) => {
      if (index > 0) url += "&";
      url += `q[id_in][]=${userId}`;
    });
    return url;
  };

  const { loading: loadingDeletedUsers, refetch: fetchDeletedUsers } = useFetch<
    UserJack[]
  >({
    woInit: true,
    url: urlBuilder(),
    formatter: defaultFormatter,
    afterSuccess: (res) => {
      const foundDeletedUsers = (res?.data ?? []) as UserJack[];

      const isNoDeletedUsersFound = foundDeletedUsers?.length <= 0;
      if (isNoDeletedUsersFound) {
        if (!!setNotFoundDeletedUserIds) {
          setNotFoundDeletedUserIds(newDeletedUserIds);
          return;
        }
      }

      let notFoundDeletedUserIds: number[] = [];
      const foundDeletedUserIds = foundDeletedUsers?.map((foundDeletedUser) =>
        Number(foundDeletedUser?.id)
      );
      newDeletedUserIds?.forEach((newDeletedUserId) => {
        if (foundDeletedUserIds?.includes(newDeletedUserId)) return;
        notFoundDeletedUserIds?.push(newDeletedUserId);
      });
      if (notFoundDeletedUserIds.length > 0) {
        if (!!setNotFoundDeletedUserIds) {
          setNotFoundDeletedUserIds((prev) =>
            removeDuplicates([...prev, ...notFoundDeletedUserIds])
          );
        }
      }

      if (!!injectDeletedUsers) {
        injectDeletedUsers(foundDeletedUsers);
      }
    },
  });

  useEffect(() => {
    const canFetchDeletedUsers =
      !loadingUsers &&
      !loadingDeletedUsers &&
      (users ?? []).length > 0 &&
      newDeletedUserIds?.length > 0;

    if (!canFetchDeletedUsers) return;

    const timeout = setTimeout(() => {
      fetchDeletedUsers();
    }, 500);

    return () => clearTimeout(timeout);
  }, [
    loadingUsers,
    loadingDeletedUsers,
    users?.length,
    newDeletedUserIds?.length,
  ]);

  return { loading: loadingDeletedUsers };
};
