import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { useDebounce, windowDimension } from "../../tools";
import Skeleton from "react-loading-skeleton";

export const useSmoothRightModal = () => {
  const [smoothToggle, setSmoothToggle] = useState(() => () => {});

  // ====== HOW TO USE =======
  // toListener => for props listener RightModal
  // toToggle => for onClick your button

  return {
    toListener: (e) => setSmoothToggle(() => e),
    toToggle: () => smoothToggle(),
  };
};

// RightModal is a very special component.
// how to use this component? Please check demo.js in this folder
export const RightModal = ({
  isOpen,
  toggle = () => {},
  array = [<div></div>],
  woClose = false,
  hideModal = false,
  listener = () => {},
  noPadding = false,
  width = undefined,
  isLoading = false,
  woTransition = false,
}) => {
  if (typeof window === "undefined") return null;
  const defaultWidth = 440;
  const rightModalWidth =
    document.getElementById("right-modal-0")?.offsetWidth || defaultWidth;
  const debouncedIsOpen = useDebounce(isOpen, 200);

  const filteredArr = array.filter((_, index) => !(index > 3));

  const ScreenWidth = () => {
    if (isLoading) return [rightModalWidth];
    if (width) return width;
    return new Array(array.length).fill(defaultWidth);
  };

  let screenWidths = ScreenWidth();

  const { width: widthWindow } = windowDimension();

  const totalWidthRaw = screenWidths.reduce((a, b) => a + b, 0);
  const isOverflow = !widthWindow ? false : totalWidthRaw > widthWindow;

  const totalWidth = isOverflow ? widthWindow - 48 : totalWidthRaw;

  const [isClosed, setIsClosed] = useState(false);

  const smoothToggle = () => {
    setIsClosed(true);
    setTimeout(() => {
      setIsClosed(false);
      toggle();
      RightModal.nextId && RightModal.nextId--;
    }, 500);
  };

  useEffect(() => {
    listener(smoothToggle);
  }, []);

  const [initialState, setInitialState] = useState(true);
  useEffect(() => setTimeout(() => setInitialState(false), 200), []);

  const translateDecider = () => {
    const reset = "48px 0px";
    if (initialState) return reset;
    if (isClosed || hideModal) return reset;
    if (debouncedIsOpen) return `-${totalWidth}px 0px`;
    return reset;
  };

  const translate = translateDecider();

  const bgColorDecider = () => {
    const reset = "right-modal-none";
    if (isClosed || hideModal || woTransition) return reset;
    if (debouncedIsOpen) return "right-modal-dark";
    return reset;
  };
  const bgColor = bgColorDecider();

  const [id, setId] = useState(null);

  // Generate and set the ID when the modal is opened
  useEffect(() => {
    if (!isOpen) return;
    if (id === null) {
      const newId = `right-modal-big-${RightModal.nextId++}`;
      setId(newId);
    }
  }, [isOpen, id, isClosed]);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        position: "absolute",
        top: -30,
        right: -totalWidth,
        height: "100vh",
        backgroundColor: "white",
        transition: woTransition ? "none" : "all 0.5s ease",
        translate,
        width: totalWidth,
        paddingBottom: 20,
        maxWidth: totalWidth,
      }}
      modalClassName={bgColor}
      contentClassName={`right-modal-content ${
        hideModal ? "fade-out" : "fade-in"
      }`}
      fade={false}
      id={id}
      backdropClassName="custom-backdrop"
    >
      {!woClose && (
        <div
          id="close_index"
          style={{
            position: "absolute",
            top: 8,
            left: -48,
            width: 40,
            height: 40,
            backgroundColor: "white",
          }}
          onClick={smoothToggle}
          className="d-flex justify-content-center align-items-center hover"
        >
          <JackIcons
            name="close"
            fill={jackColors.black34}
            style={{ width: 20, height: 20 }}
          />
        </div>
      )}
      {isLoading ? (
        <ShimmerRightModal />
      ) : (
        <div className="d-flex w-100" style={{ height: "100vh" }}>
          {filteredArr.map((screen, index) => {
            const isFirst = index == 0;
            const screenWidth = screenWidths[index];
            if (isOverflow)
              return (
                <div
                  style={{
                    width: isOverflow
                      ? totalWidth / filteredArr.length
                      : screenWidth,
                    height: "100%",
                    borderLeft: isFirst ? "" : `1px solid ${jackColors.greyE6}`,
                    padding: noPadding ? 0 : 16,
                    paddingBottom: noPadding ? 0 : 20,
                    overflowY: "auto",
                    overflowX: isOverflow ? "auto" : "hidden",
                  }}
                  key={index}
                  id={`right-modal-${index}`}
                >
                  <div style={{ width: screenWidth, overflowX: "auto" }}>
                    {screen}
                  </div>
                </div>
              );
            return (
              <div
                style={{
                  width: isOverflow
                    ? totalWidth / filteredArr.length
                    : screenWidth,
                  height: "100%",
                  borderLeft: isFirst ? "" : `1px solid ${jackColors.greyE6}`,
                  padding: noPadding ? 0 : 16,
                  paddingBottom: noPadding ? 0 : 20,
                  overflowY: "auto",
                  overflowX: isOverflow ? "auto" : "hidden",
                }}
                key={index}
                id={`right-modal-${index}`}
              >
                {screen}
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};
RightModal.nextId = 0; // Initialize the ID counter outside the component

const ShimmerRightModal = () => {
  return (
    <div style={{ padding: 16 }}>
      <div style={{ marginTop: 32 }}>
        <Skeleton width="100%" height="25px" />
        <Skeleton width="100%" height="25px" className="mt-2" />
        <Skeleton width="100%" height="25px" className="mt-2" />
        <Skeleton width="100%" height="100px" className="mt-5" />
        <Skeleton width="100%" height="150px" className="mt-5" />
      </div>
    </div>
  );
};
